import React, { useState, useEffect, useRef } from "react";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './caro.css';
import CountUp from 'react-countup';
import moment from 'moment';
import { loadApi } from "services/appApi";

const CellAssembly = () => {

  const [dataLength, setDataLength] = useState(0);
  console.log("dataLength",dataLength);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [head, setHead] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [LoadingErrors, setLoadingErrors] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [currentHours, setCurrentHours] = useState(parseInt(moment(new Date()).format('HH'), 10));
  const [currentMinutes, setCurrentMinutes] = useState(parseInt(moment(new Date()).format('mm'), 10));

  const p_param = {
    p_process_id: 100,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        let res = await loadApi(
          p_param,
          "/api/dashboard/getProcProductionStatus"
        );
        //        let res = await getDataApi("EffOpTimePageLoad");
        if (res) {
          let successFlag = res.data[0];

          //   console.log(res);
          if (successFlag === "Y") {
            const dataArray = Array.isArray(res.data[2]) ? res.data[2] : [];
            const length = dataArray.length;
            console.log("length: ",length );
            setDataLength(length);
            if(length === 1){
              const fetchedData = res.data[2]?.[0];
              console.log("Fetched Data: ", fetchedData);
              setData(fetchedData);
            } else {
              const fetchedData1 = res.data[2]?.[0];
              const fetchedData2= res.data[2]?.[1];
              console.log("Fetched Data1: ", fetchedData1);
              console.log("Fetched Data2: ", fetchedData2);
              setData1(fetchedData1);
              setData2(fetchedData2);
            }
            setHead(res.data[1]);
            console.log("month",head);
            console.log(res.data[2]);
            console.log("line1", data1);
            const now = new Date();
            setCurrentHours(parseInt(moment(now).format('HH'), 10));
            setCurrentMinutes(parseInt(moment(now).format('mm'), 10));
            setLastUpdated(now);
            setRefreshKey(prevKey => prevKey + 1);
            setIsLoading(false);
          } else {
            setLoadingErrors(res.data[3]);
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        console.log(err);
        setLoadingErrors(err);
      }
    };
    fetchData();
    const intervalId = setInterval(fetchData,  60000);
    return () => {clearInterval(intervalId);
    }
  }, []);

  const formatTime = (value) => value.toString().padStart(2, '0');
  const startHours = 0;
  const endHours = currentHours;
  const startMinutes = 0;
  const endMinutes = currentMinutes;

  return (
    <div>
    <header style={styles.header}>
    <div style={styles.content}>
      <div style={styles.heading}>Cell Production Status</div>
      <div style={styles.timeContainer}>
        <span style={styles.labelText}>As of:</span>
        <span style={styles.timeText}><h1 style={{ margin: 0, padding: 0, fontSize: '5rem',fontWeight :'bold'}}>
        <CountUp
            key={`currentHours-${refreshKey}`}
            start={startHours}
            end={endHours}
            duration={2}
            separator=""
            decimals={0}
            prefix=""
            useEasing={true}
            formattingFn={value => formatTime(Math.round(value))}
          />
        </h1>
        <h1 style={{ margin: '0 0.5rem', padding: 0,fontSize: '5rem',fontWeight :'bold' }}>:</h1>
        <h1 style={{ margin: '0 0 0 10px', padding: 0, fontSize: '5rem',fontWeight :'bold' }}>
        <CountUp
            key={`currentMinutes-${refreshKey}`}
            start={startMinutes}
            end={endMinutes}
            duration={2}
            separator=""
            decimals={0}
            prefix=""
            useEasing={true}
            formattingFn={value => formatTime(Math.round(value))}
          />
        </h1></span>
      </div>
    </div>
  </header>
  <div className="data-table-container" style={styles. dataTableContainer}>
    <div className="row justify-content-center">
    <div className="col">
      <table style={{ tableLayout: 'fixed', width: '100%' }} className="table table-borderless table-dashboard mb-0 lh-1 text-center">
        <thead > 
          <tr >
            <th style={{ backgroundColor: 'darkblue', padding: '1.25rem' }} className="w-25" />
            <th style={{ backgroundColor: 'darkblue', textAlign: 'center', padding: '1.25rem' }} className="w-37">
              <span style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem', fontWeight:'normal'}} className="text-table-dashboard text-table-dashboard-heading">Line 1</span>
            </th>
            <th style={{ backgroundColor: 'darkblue', textAlign: 'center', padding: '1.25rem'  }} className="w-37">
              <span style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem', fontWeight:'normal'}}class="text-table-dashboard text-table-dashboard-heading">Line 2</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem',fontWeight:'normal', padding: '1.25rem' }}class="align-middle">
              Plan Qty
            </th>
            {dataLength === 1 ? (<><td style={{ backgroundColor: 'darkblue', padding: '1.25rem' }} className="fw-bold">
              <span style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem' }} className="text-table-dashboard text-table-dashboard-empty">
                {data.line_number == '1' && data.plan_qty != null ? (
        <CountUp  key={`plan_qty-${refreshKey}`} start={0} end={data.plan_qty} duration={2} />
      ) : (
        "N/A"
      )}
              </span>
            </td>
            <td style={{ backgroundColor: 'darkblue', padding: '1.25rem' }} className="fw-bold">
              <span style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem' }} className="text-table-dashboard text-table-dashboard-empty">
                {data.line_number == '2' && data.plan_qty != null ? (<CountUp key={`plan_qty-${refreshKey}`} start={0} end={data.plan_qty} duration={2} />
      ) : (
        "N/A"
      )}
              </span>
            </td>
            </>):(<>
            <td style={{ backgroundColor: 'darkblue', padding: '1.25rem' }} className="fw-bold">
              <span style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem' }} className="text-table-dashboard text-table-dashboard-empty">
                {data1.line_number == '1' && data1.plan_qty != null ?  (
        <CountUp key={`plan_qty-${refreshKey}`} start={0} end={data1.plan_qty} duration={2}  />
      ) : (
        "N/A"
      )}
              </span>
            </td>
            <td style={{ backgroundColor: 'darkblue', padding: '1.25rem' }} className="fw-bold">
              <span style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem' }} className="text-table-dashboard text-table-dashboard-empty">
                {data2.line_number == '2' && data2.plan_qty != null ? (
        <CountUp key={`plan_qty-${refreshKey}`} start={0} end={data2.plan_qty} duration={2} />
      ) : (
        "N/A"
      )}
              </span>
            </td>
            </>)}
            </tr>
            <tr>
            <th style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem', fontWeight:'normal', padding: '1.25rem' }}  className="align-middle">
              Prod Qty
            </th>
            {dataLength === 1 ? (<><td style={{ backgroundColor: 'darkblue' , padding: '1.25rem'}} className="fw-bold">
              <span style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem' }} className="text-table-dashboard text-table-dashboard-empty">
                {data.line_number == '1' && data.prod_qty != null ? (
        <CountUp key={`prod_qty-${refreshKey}`} start={0} end={data.prod_qty} duration={2} />
      ) : (
        "N/A"
      )}
              </span>
            </td>
            <td style={{ backgroundColor: 'darkblue', padding: '1.25rem' }} className="fw-bold">
              <span style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem' }} className="text-table-dashboard text-table-dashboard-empty">
                {data.line_number == '2' && data.prod_qty != null ? (
        <CountUp key={`prod_qty-${refreshKey}`} start={0} end={data.prod_qty} duration={2} />
      ) : (
        "N/A"
      )}
              </span>
            </td>
            </>):(<>
            <td style={{ backgroundColor: 'darkblue' , padding: '1.25rem'}} className="fw-bold">
              <span style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem' }} className="text-table-dashboard text-table-dashboard-empty">
                {data1.line_number == '1' && data1.prod_qty != null ? (
        <CountUp key={`prod_qty-${refreshKey}`} start={0} end={data1.prod_qty} duration={2} />
      ) : (
        "N/A"
      )}
              </span>
            </td>
            <td style={{ backgroundColor: 'darkblue', padding: '1.25rem' }} className="fw-bold">
              <span style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem' }} className="text-table-dashboard text-table-dashboard-empty">
                {data2.line_number == '2' && data2.prod_qty != null ? (
        <CountUp key={`prod_qty-${refreshKey}`} start={0} end={data2.prod_qty} duration={2} />
      ) : (
        "N/A"
      )}
              </span>
            </td>
            </>)}
            </tr>
            <tr>
            <th style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem',fontWeight:'normal', padding: '1.25rem' }}class="align-middle">
              Mach Eff
            </th>
            {dataLength === 1 ? (<><td style={{ backgroundColor: 'darkblue' , padding: '1.25rem'}} className="fw-bold">
              <span style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem' }} class="text-table-dashboard text-table-dashboard-empty">
                {data.line_number == '1' && data.mach_efficiency != null ? (
        <CountUp key={`mach_efficiency-${refreshKey}`} start={0} end={data.mach_efficiency} duration={2} suffix="%" />
      ) : (
        "N/A"
      )}
              </span>
            </td>
            <td style={{ backgroundColor: 'darkblue', padding: '1.25rem' }} className="fw-bold">
              <span style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem' }} className="text-table-dashboard text-table-dashboard-empty">
                {data.line_number == '2' && data.mach_efficiency != null ?  (
        <CountUp key={`mach_efficiency-${refreshKey}`} start={0} end={data.mach_efficiency} duration={2} suffix="%" />
      ) : (
        "N/A"
      )}
              </span>
            </td>
            </>) :(<>
            <td style={{ backgroundColor: 'darkblue' , padding: '1.25rem'}} className="fw-bold">
              <span style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem' }} class="text-table-dashboard text-table-dashboard-empty">
                {data1.line_number == '1' && data1.mach_efficiency != null ? (
        <CountUp  key={`mach_efficiency-${refreshKey}`} start={0} end={data1.mach_efficiency} duration={2} suffix="%" />
      ) : (
        "N/A"
      )}
              </span>
            </td>
            <td style={{ backgroundColor: 'darkblue', padding: '1.25rem' }} className="fw-bold">
              <span style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem' }} className="text-table-dashboard text-table-dashboard-empty">
                {data2.line_number == '2' && data2.mach_efficiency != null ?(
        <CountUp  key={`mach_efficiency-${refreshKey}`} start={0} end={data2.mach_efficiency} duration={2} suffix="%"  />
      ) : (
        "N/A"
      )}
              </span>
            </td>
            </>)}
            </tr>
            </tbody>
            </table>
            </div>
            </div>
            <div className="row justify-content-center">
    <div className="col">
      <Carousel
        id="demo"
        interval={2000} 
        pause={false}   
        controls={false} 
        indicators={false} 
        className="carousel border-end-0 border-bottom-0 border-start-0 border-top border-3 slide pt-4"
      >
        <Carousel.Item>
        <table style={{ tableLayout: 'fixed', width: '100%' }} className="table table-borderless table-dashboard text-center lh-1">
              <tbody>
                <tr>
                  <th  style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem',fontWeight:'normal', padding: '0.9375rem'}} className="w-25">
                      {head} Prod Qty
                  </th>
                  {dataLength === 1 ? (<> <td style={{ backgroundColor: 'darkblue', padding: '3.75rem' }} className="w-37 fw-bold">
                    <span style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem' }} className="text-table-dashboard text-table-dashboard-empty">
                      {data.line_number == '1' && data.month_prod_qty != null ?  (
        <CountUp  key={`month_prod_qty-${refreshKey}`} start={0} end={data.month_prod_qty} duration={2} />
      ) : (
        "N/A"
      )}
                    </span>
                  </td>
                  <td  style={{ backgroundColor: 'darkblue' , padding: '3.75rem'}}class="w-37 fw-bold">
                    <span style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem' }} className="text-table-dashboard text-table-dashboard-empty">
                      {data.line_number == '2' && data.month_prod_qty != null ?  (
        <CountUp key={`month_prod_qty-${refreshKey}`} start={0} end={data.month_prod_qty} duration={2} />
      ) : (
        "N/A"
      )}
                    </span>
                  </td>
                  </>) : (<>
                  <td style={{ backgroundColor: 'darkblue', padding: '3.75rem' }} className="w-37 fw-bold">
                    <span style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem' }} className="text-table-dashboard text-table-dashboard-empty">
                      {data1.line_number == '1' && data1.month_prod_qty != null ?   (
        <CountUp key={`month_prod_qty-${refreshKey}`} start={0} end={data1.month_prod_qty} duration={2} />
      ) : (
        "N/A"
      )}
                    </span>
                  </td>
                  <td  style={{ backgroundColor: 'darkblue' , padding: '3.75rem'}}class="w-37 fw-bold">
                    <span style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem' }} className="text-table-dashboard text-table-dashboard-empty">
                      {data2.line_number == '2' && data2.month_prod_qty != null ?  (
        <CountUp key={`month_prod_qty-${refreshKey}`} start={0} end={data2.month_prod_qty} duration={2} />
      ) : (
        "N/A"
      )}
                    </span>
                  </td>
                  </>)}
                </tr>
                </tbody>
            </table>
        </Carousel.Item>
        <Carousel.Item>
        <table style={{ tableLayout: 'fixed', width: '100%' }} className="table table-borderless table-dashboard text-center lh-1">
              <tbody>
                <tr>
                  <th style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem',fontWeight:'normal', padding: '0.9375rem',paddingTop: '3.75rem' }} className="w-25">
                    {head} Achmt
                  </th>
                  {dataLength === 1 ? (<> <td style={{ backgroundColor: 'darkblue', padding: '3.75rem' }} className="w-37 fw-bold">
                      <span style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem' }} className="text-table-dashboard text-table-dashboard-empty">
                        {data.line_number == '1' && data.month_achievement != null ?  (
        <CountUp key={`month_achievement-${refreshKey}`} start={0} end={data.month_achievement} duration={2} />
      ) : (
        "N/A"
      )}
                      </span>
                    </td>
                    <td style={{ backgroundColor: 'darkblue' , padding: '3.75rem'}} className="w-37 fw-bold">
                      <span style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem' }} className="text-table-dashboard text-table-dashboard-empty">
                        {data.line_number == '2' && data.month_achievement != null ? (
        <CountUp key={`month_achievement-${refreshKey}`} start={0} end={data.month_achievement} duration={2} suffix="%"  />
      ) : (
        "N/A"
      )}
                      </span>
                    </td>
                    </>) : (<>
                  <td style={{ backgroundColor: 'darkblue', padding: '3.75rem' }} className="w-37 fw-bold">
                      
                    <span style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem' }} className="text-table-dashboard text-table-dashboard-empty">
                      {data1.line_number == '1' && data1.month_achievement != null ? (
        <CountUp key={`month_achievement-${refreshKey}`} start={0} end={data1.month_achievement} duration={2} suffix="%"  />
      ) : (
        "N/A"
      )}
                    </span>
                  </td>
                  <td style={{ backgroundColor: 'darkblue' , padding: '3.75rem'}} className="w-37 fw-bold">
                    <span style={{ backgroundColor: 'darkblue', color: 'white', fontSize: '5.625rem' }} className="text-table-dashboard text-table-dashboard-empty">
                      {data2.line_number == '2' && data2.month_achievement != null ? (
        <CountUp key={`month_achievement-${refreshKey}`} start={0} end={data2.month_achievement} duration={2} suffix="%"  />
      ) : (
        "N/A"
      )}
                    </span>
                  </td>
                  </>)}
                </tr>
              </tbody>
              </table>
        </Carousel.Item>
      </Carousel>
    </div>
  </div>
     </div>
   </div>
  );
};

const styles = {
  header: {
    backgroundColor: 'darkblue',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '1.25rem',
  },
  content: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  heading: {
    color: 'white',
    fontSize: '5.625rem',
    fontWeight :'bold',
    flex: 1,
    textAlign: 'center',
    paddingLeft: '11.25rem'
  },
  timeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    color: 'white',
  },
  labelText: {
    fontSize: '5.625rem',
    fontWeight :'bold',
  },
  timeText: {
    display: 'flex', 
    alignItems: 'center' 
    
  },
   dataTableContainer: {
    flex: 1,
    overflow: 'auto',
    padding: '0.625rem',
    width: '100%',
    height: '100%',
    backgroundColor:"darkblue",
  },
 
};

export default CellAssembly;


