import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "assets/scss/dashboard.scss";
import "assets/css/charts_form_controls.css";
import React, { useState, useRef, useLayoutEffect } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import numeral from 'numeral';
import "assets/css/grid_table.css";
import { getDataApi, saveApi } from "services/appApi";
import { pecaPageRows, pecaRowsPerPageOptions } from "pages/common/envPECA";

const PollDataArchives = () => {
  let emptyRow = {
              archive_id: "",
              archive_date: "",
              table_name: "",
              no_of_rows_archived: "",
              base_dir_name: "",
              sub_dir_name: "",
              file_name: "",
              restored_date: "",
              no_of_rows_restored: "",
              comments: "",
              remote_ip_address: "",
              remote_user_name: "",
              remote_dir_name: "",
              remote_transfer_date: "",
              remote_file_size_bytes: "",
              remote_file_size_mb: "",
              local_file_size_bytes: "",
              local_file_size_mb:"",
              local_file_delete_date: "",
              created_by: "",
              creation_date: "",
              last_updated_by: "",
              last_update_date: ""
            };

  /**** 31-May-2024 Velmani P. Following changes are made to template
   * 	a. Use a generic name "row" instead of "product".  Following are the name changed from template
   * 	   a1. products ==> rows
   * 		[products, setProducts] ==> [products, setRows]
   * 		[deleteProductsDialog, setDeleteProductsDialog] ==> [deleteRowsDialog, setDeleteRowsDialog]
   * 		[selectedProducts, setSelectedProducts] ==> [selectedRows, setSelectedRows]
   * 	   a2. product  ==> row
   *		[productDialog, setProductDialog] ==> [productDialog, setRowDialog]
   *		[deleteProductDialog, setDeleteProductDialog] ==> [deleteRowDialog, setDeleteRowDialog]
   *		[product, setProduct] ==> [product, setRow]
   * ******/
  const [rows, setRows] = useState([]);
  const [rowDialog, setRowDialog] = useState(false);
  const [showproductDialog, setShowProductDialog] = useState(false);
  const [row, setRow] = useState(emptyRow);
  const [selectedRows, setSelectedRows] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const toast = useRef(null);
  const dt = useRef(null);

  // Fetch Machines when the component mounts
  useLayoutEffect(() => {
    const fetchRows = async () => {
      try {
        setIsLoading(true);
        const response = await getDataApi("/api/setup/pollDataArchive/selRows");
        console.log("Fetched machines:", response.data);
        console.log(response.data[0]);
        console.log(response.data[1]);
        if (response.data.length > 0) {
          let successFlag = response.data[0];
          if (successFlag === "Y") {
            console.log("success=Y");
            setRows(response.data[1]);
            setIsLoading(false);
          } else {
            //setRows(null);
            setRows([]);
            setIsLoading(false);
            const errorInfo = response.data[2];

            // Set the error message and display the dialog
            setErrorMessage(errorInfo);
            setErrorDialogVisible(true);
          }
          //setSearch(response.data);
        } else {
          //setRows(null);
          setRows([]);
          setIsLoading(false);
        }

        //setRows(response.data);
      } catch (error) {
        console.error("Error fetching machines:", error);
      }
    };

    fetchRows();
  }, []);


  const hideDialog = () => {
    setSubmitted(false);
    setRowDialog(false);
    setShowProductDialog(false);
    setErrorDialogVisible(false);
  };


  /***
        const formatDateFields = (row, isNew) => {
            const formatDate = (date) => date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null;
    
            return {
                ...row,
                creation_date: isNew ? formatDate(new Date()) : formatDate(row.creation_date),
                last_update_date: formatDate(new Date()),
                start_date: formatDate(row.start_date),
                end_date: formatDate(row.end_date)
            };
        };
         */

  const saveRow = async () => {
    setSubmitted(true);

    if (row.table_name.trim()) {
      //      const isNew = !row.machine_id;
      //     const formattedRow = formatDateFields(row, isNew);

      let _rows = [...rows];
      let _row = { ...row };

      try {
        if (row.archive_id) {
          /*** Update Row */
          const response = await saveApi(row, "/api/setup/pollDataArchive/updRow");
          //await axios.put(`http://localhost:5000/api/machines/${row.machine_id}`, formattedRow);
          //             const updatedRows = rows.map((p) => (p.machine_id === row.machine_id ? formattedRow : p));
          //             setRows(updatedRows);

          let successFlag = response.data[0];
          if (successFlag === "Y") {
            const index = findIndexById(row.archive_id);
            _rows[index] = _row;
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Comments Updated",
              life: 3000,
            });
          } else {
            let err_msg = response.data[1];
            toast.current.show({
              severity: "error",
              summary: err_msg,
              detail: "Poll Data Archives Not Updated",
              life: 3000,
            });
          }
        }
        setRows(_rows);
        setRowDialog(false);
        setRow(emptyRow); 
      } catch (error) {
        console.error("Error saving machine:", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Poll Data Archives Save Failed",
          life: 3000,
        });
      }
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please fill in the comment field",
        life: 3000,
      });
    }
  };

  const editRow = (row) => {
    setRow({ ...row });
    setRowDialog(true);
  };

  const showProduct = (row) => {
    setRow({ ...row });
    setShowProductDialog(true);
}

  const findIndexById = (id) => {
    let index = -1;
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].machine_id === id) {
        index = i;
        break;
      }
    }
    return index;
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };


  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _row = { ...row };
    _row[`${name}`] = val;

    setRow(_row);
  };

  /***
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const headerDontUse = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Machines</h5>
            {/* <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span> * /}
        </div>
    );
    */
    const getLoadingHeaderContents = () => {
      return (
        <React.Fragment>
          <div className="row d-flex justify-content-center m-5">
            <div className="col-auto">
              <div className="card shadow-sm border-primary">
                <div className="card-header bg-primary text-white">
                  <h2 className="mb-0">
                  Poll Data Archives
                  </h2>
                </div>
                <div className="card-body text-center">
                  <h3>
                    <i className="fa-duotone fa-fw fa-spin-pulse fa-loader me-3"></i>{" "}
                    The page is loading...
                  </h3>
                </div>
              </div>
            </div>
          </div>
    
        </React.Fragment>
      );
    }
    

  const header = (
    <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
      {/* <span className="p-input-icon-right w-full md:w-auto">
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto"
        />
      </span> */}
       <div className="mt-3 md:mt-0 flex md:flex-1 justify-content-end">
        <Button
          icon="pi pi-upload"
          className="p-button-primary p-button-rounded"
          onClick={exportCSV}
          tooltip="Export"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>
    </div>
  );
  const getLoadedHeaderContents = () => {
    return (
      <React.Fragment>
        <div className="header-container">
          <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
          <i>
             
            
            <span className="normal-font"> Poll Data Archives </span><br/><br/>
            <div className="header-container p-1">
                <style>
                {`
                  input::placeholder {
                    font-size: 17px;
                  }
                `}
              </style>
            <span className="p-input-icon-right w-full md:w-auto"> 
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search..."
            className="w-full lg:w-auto text-sm p-3"
            style={{ fontSize: '17px' }} 
          />
        </span>
            </div>
         
            </i>
          </h2>
        </div>
      </React.Fragment>
    );
  }

  const archivedateTemplate = (rowData) => {
    return moment(rowData.archive_date).format('YYYY-MM-DD');
  }

  const localdateTemplate = (rowData) => {
    return moment(rowData.local_file_delete_date).format('YYYY-MM-DD HH:mm:ss');
  }

  const restoreddateTemplate = (rowData) => {
    return moment(rowData.restored_date).format('YYYY-MM-DD HH:mm:ss');
  }

  const transferdateTemplate = (rowData) => {
    return moment(rowData.remote_transfer_date).format('YYYY-MM-DD HH:mm:ss');
  }

  const rowArchiveTemplate = (rowData) => {
    return numeral(rowData.no_of_rows_archived).format('0,0');
  }

  const remotefileTemplate = (rowData) => {
    return numeral(rowData.remote_file_size_mb).format('0,0');
  }

  const rowrestoredTemplate = (rowData) => {
    return numeral(rowData.no_of_rows_restored).format('0,0');
  }

  const formattedDate = moment(row.archive_date).format('DD-MM-YYYY');

  const localDate = moment(row.local_file_delete_date).format('YYYY-MM-DD HH:mm:ss');

  const restoredDate = moment(row.restored_date).format('YYYY-MM-DD HH:mm:ss');

  const transferDate = moment(row.remote_transfer_date).format('YYYY-MM-DD HH:mm:ss');

  const rowArchiveNumber = numeral(row.no_of_rows_archived).format('0,0');

  const remotefileNumber = numeral(row.remote_file_size_mb).format('0,0');

  const rowrestoredNumber = numeral(row.no_of_rows_restored).format('0,0');

  const readBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-primary"
          onClick={() => showProduct(rowData)}
          tooltip="View"
          tooltipOptions={{ position: "bottom" }}
        />
      </React.Fragment>
    );
  };

  const updateBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-primary p-mr-2"
          onClick={() => editRow(rowData)}
          tooltip="Edit"
          tooltipOptions={{ position: "bottom" }}
        />
      </React.Fragment>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="col-12">
      {isLoading ? getLoadingHeaderContents() : getLoadedHeaderContents()}
      {!isLoading && (
      <div className="card">
        {/* <div className="text-3xl text-800 font-bold mb-4">Poll Data Archives</div> */}
        <DataTable
          ref={dt}
          value={rows}
          scrollable scrollHeight="flex"   
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          dataKey="archive_id"
          className="custom-datatable-border"
          tableStyle={{ minWidth: '50rem' }}
          sortField="archive_date"    
          sortOrder={-1}
          paginator
          rows={pecaPageRows}
          rowsPerPageOptions={pecaRowsPerPageOptions}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} data"
          globalFilter={globalFilter}
          header={header}
          emptyMessage="No data found"
          stripedRows
          showGridlines
        >
  
                    <Column body={readBodyTemplate} exportable={false} style={{width:'0.75rem' }}></Column>
                    <Column body={updateBodyTemplate} exportable={false} style={{width:'0.75rem' }}></Column>
                    <Column field="archive_id" header="id" sortable="false" hidden="true" exportable={false} ></Column>
                    <Column field="archive_date" header="Poll Date" body={archivedateTemplate} sortable style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="local_file_delete_date" header="Archive Date" body={localdateTemplate} sortable style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="no_of_rows_archived" header="No of Rows" body={rowArchiveTemplate} sortable style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="remote_file_size_mb" header="File Size(MB)" body={remotefileTemplate} sortable style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="remote_dir_name" header="Directory" sortable style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="file_name" header="File Name" sortable style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="restored_date" header="Data restored on" body={restoreddateTemplate} sortable style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="no_of_rows_restored" header="No of Rows restored" body={rowrestoredTemplate} sortable style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="table_name" header="Poll Table Name" sortable style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="remote_ip_address" header="Remote Server" sortable style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="remote_user_name" header="Remote User" sortable style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="remote_transfer_date" header="Remote ftp date" sortable body={transferdateTemplate} style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="local_file_size_bytes" header="Unix File Size(Bytes)" sortable style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="base_dir_name" header="Unix Dir" sortable style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column>
                    <Column field="comments" header="Comments" sortable style={{ minWidth: '12rem', whiteSpace: 'nowrap' }}></Column> 
                    
        </DataTable>
      </div>
      )}

      <Dialog visible={rowDialog} style={{ width: "600px" }} header="Poll Data Archives" modal className="p-fluid" onHide={hideDialog} >

                <div className="field">
                    <label htmlFor="comments">Comments</label>
                    <InputTextarea id="comments" value={row.comments} onChange={(e) => onInputChange(e, 'comments')} required rows={3} cols={20} autoFocus className={classNames({ 'p-invalid': submitted && !row.comments })} />
                    {submitted && !row.comments && <small className="p-error">Comments is required.</small>}
                </div>

                <label htmlFor="archive_date">Poll Date</label>
                    <div className="box">
                        {formattedDate}
                    </div>

                <label htmlFor="local_file_delete_date">Archive Date</label>
                    <div className="box">
                        {localDate}
                    </div>

                <label htmlFor="no_of_rows_archived">No of Rows</label>
                    <div className="box">
                        {rowArchiveNumber}
                    </div>

                <label htmlFor="remote_file_size_mb">File Size(MB)</label>
                    <div className="box">
                        {remotefileNumber}
                    </div>

                <label htmlFor="remote_dir_name">Directory</label>
                    <div className="box">
                        {row.remote_dir_name}
                    </div>

                <label htmlFor="file_name">File Name</label>
                    <div className="box">
                        {row.file_name}
                    </div>

                <label htmlFor="restored_date">Data restored on</label>
                    <div className="box">
                        {restoredDate}
                    </div>

                <label htmlFor="no_of_rows_restored">No of Rows restored</label>
                    <div className="box">
                        {rowrestoredNumber}
                    </div>

                <label htmlFor="table_name">Poll Table Name</label>
                    <div className="box">
                        {row.table_name}
                    </div>

                <label htmlFor="remote_ip_address">Remote Server</label>
                    <div className="box">
                        {row.remote_ip_address}
                    </div>
                
                <label htmlFor="remote_user_name">Remote User</label>
                    <div className="box">
                        {row.remote_user_name}
                    </div>

                <label htmlFor="remote_transfer_date">Remote ftp date</label>
                    <div className="box">
                        {transferDate}
                    </div>

                <label htmlFor="local_file_size_bytes">Unix File Size(Bytes)</label>
                    <div className="box">
                        {row.local_file_size_bytes}
                    </div>

                <label htmlFor="base_dir_name">Unix Dir</label>
                    <div className="box">
                        {row.base_dir_name}
                    </div>

                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
                <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveRow} />
      </Dialog>

      <Dialog visible={showproductDialog} style={{ width: '600px' }} header="Poll Data Archives" modal className="p-fluid" onHide={hideDialog}>
                
                <label htmlFor="archive_date">Poll Date</label>
                    <div className="box">
                        {formattedDate}
                    </div>

                <label htmlFor="local_file_delete_date">Archive Date</label>
                    <div className="box">
                        {localDate}
                    </div>

                <label htmlFor="no_of_rows_archived">No of Rows</label>
                    <div className="box">
                        {rowArchiveNumber}
                    </div>

                <label htmlFor="remote_file_size_mb">File Size(MB)</label>
                    <div className="box">
                        {remotefileNumber}
                    </div>

                <label htmlFor="remote_dir_name">Directory</label>
                    <div className="box">
                        {row.remote_dir_name}
                    </div>

                <label htmlFor="file_name">File Name</label>
                    <div className="box">
                        {row.file_name}
                    </div>

                <label htmlFor="restored_date">Data restored on</label>
                    <div className="box">
                        {restoredDate}
                    </div>

                <label htmlFor="no_of_rows_restored">No of Rows restored</label>
                    <div className="box">
                        {rowrestoredNumber}
                    </div>

                <label htmlFor="table_name">Poll Table Name</label>
                    <div className="box">
                        {row.table_name}
                    </div>

                <label htmlFor="remote_ip_address">Remote Server</label>
                    <div className="box">
                        {row.remote_ip_address}
                    </div>
                
                <label htmlFor="remote_user_name">Remote User</label>
                    <div className="box">
                        {row.remote_user_name}
                    </div>

                <label htmlFor="remote_transfer_date">Remote ftp date</label>
                    <div className="box">
                        {transferDate}
                    </div>

                <label htmlFor="local_file_size_bytes">Unix File Size(Bytes)</label>
                    <div className="box">
                        {row.local_file_size_bytes}
                    </div>

                <label htmlFor="base_dir_name">Unix Dir</label>
                    <div className="box">
                        {row.base_dir_name}
                    </div>

                <label htmlFor="comments">Comments</label>
                  <div className="box">
                    {row.comments}
                  </div>
            </Dialog>
            <Dialog 
                header="API Error" 
                visible={errorDialogVisible} 
                style={{ width: '400px' }} 
                onHide={hideDialog} 
                footer={
                    <Button label="OK" onClick={hideDialog} className="p-button-primary p-button-rounded" />
                }
            >    
             <p>

                    {errorMessage}
                </p>
            </Dialog>
            </div>
    </div>
  );
};

export default PollDataArchives;
