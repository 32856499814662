import React, { useEffect, useState } from "react";
import { loadApi } from "services/appApi";
import moment from "moment";
import ShowEffOpTimeChart from "pages/charts/EffOpTime/ShowEffOpTimeChart.jsx";
import "primeicons/primeicons.css";
import "assets/css/charts_form_controls.css";

function FormControlListSelector(props) {
  const [processId, setProcessId] = useState();
  const [machineId, setMachineId] = useState();
  const [machines, setMachines] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [wasDataFetchingSuccessful, setWasDataFetchingSuccessful] =useState(true);
  const [chartData, setChartData] = useState([]);
  const [targetEfficiency, setTargetEfficiency] = useState();
  const [error, setError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [flag, setFlag] = useState('');
  const [nFlagErrorMsg, setnFlagErrorMsg] = useState('');
  const [chartForm, setChartForm] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [hasAttemptedFetch, setHasAttemptedFetch] = useState(false);
  const [machineRenderedAsRadio, setMachineRenderedAsRadio] = useState(false);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const firstRowProcessId = props.processes[0].id;
        setProcessId(firstRowProcessId);

        const filteredMachines = props.machines.filter(
          (data) => data.process_id === firstRowProcessId
        );
        setMachines(filteredMachines);
        setMachineId(
          filteredMachines.length > 0 ? filteredMachines[0].id : null
        );

        const yesterday = moment().subtract(1, "days").set({
          hour: 7,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        let formattedStartDate = yesterday.format("yyyy-MM-DD HH:mm");
        setStartDate(formattedStartDate);

        const tomorrow = moment().add(0, "days").set({
          hour: 6,
          minute: 59,
          second: 0,
          millisecond: 0,
        });
        let formattedEndDate = tomorrow.format("yyyy-MM-DD HH:mm");
        setEndDate(formattedEndDate);

        setMachineRenderedAsRadio(filteredMachines.length <= 2);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [props.processes, props.machines]);

  const handleLoadChart = async () => {
    let selectedMachineRec = machines.find((dt) => dt.id == machineId);

    if (!selectedMachineRec) {
      setError(true);
      return;
    }

    const p_param = {
      p_machine_id: selectedMachineRec.id,
      p_start_datetime: startDate,
      p_end_datetime: endDate,
    };

    setIsLoading(true);
    setHasAttemptedFetch(true); 
    setChartForm({
      ...chartForm,
      machineDesc: selectedMachineRec?.description || "Winding Machine #6",
      data: p_param,
    });
    await loadApi(p_param, "/api/chart/getMachEffByOpTime")
    .then(async (result) => {
      //    await loadApi(p_param, "GetHourlyMachineEffOpTimeByIdAndTimespan").then(async (result) => {
      if (result) {
        let successFlag = result.data[0];
        setFlag(successFlag);
        if (successFlag === "Y") {
          if (result.data[1].length > 0) {
            setChartData(result.data[1]);
            console.log("result.data[1]", result.data[1]);
            setTargetEfficiency(result.data[2]);
            setWasDataFetchingSuccessful(false);
            setError(false);
          } else {
            setErrorStatus(204);
            setError(true);
            setWasDataFetchingSuccessful(true);
          }
        } else {
          setError(true);
          if(result.data[2] && result.data[2].length > 0){
            const extractedErrMsg = result.data[2].map((item) => item.error_msg);
            setnFlagErrorMsg(extractedErrMsg);
          }
        }
        setIsLoading(false);
      } else {
        setWasDataFetchingSuccessful(true);
        setIsLoading(false);
      }
    })
    .catch((err) => {
      console.error("Error",err);
      setErrorStatus(err.response ? err.response.status : null);
      setErrorMessage(err.message)
      setIsLoading(false);
      setError(true);
    });
};
  
  const handleProcessChange = (e) => {
    const selectedProcessId = e.target.value;
    setProcessId(selectedProcessId);

    const filteredMachines = props.machines.filter(
      (dt) => dt.process_id == selectedProcessId
    );
    setMachines(filteredMachines);
    setMachineRenderedAsRadio(filteredMachines.length <= 2);
    setMachineId(filteredMachines.length > 0 ? filteredMachines[0].id : null);
  };

  const handleMachineChange = (e) => {
    setMachineId(parseInt(e.target.value));
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };


  const getProcessControl = () => (
    <React.Fragment>
      <div className="col-auto px-1 d-flex flex-column">
        <label
          className={
            props.processes.length === 1 ? "form-label mb-3" : "form-label"
          }
        >
          Process
        </label>
        <div className="form-check">
          {props.processes.map((input) => (
            <div key={input.id}>
              <input
              inputId={`${props.chartId}ProcessRadBtn${input.id}`}
                className="form-check-input"
                type="radio" 
                name={`${props.chartId}ProcessRadBtn`}
                value={input.id}
                onChange={handleProcessChange}
                checked={processId == input.id}
                disabled={isLoading}
              />
              <label
                className="form-check-label"
                htmlFor={`${props.chartId}ProcessRadBtn${input.id}`}
              >
                {input.description}
              </label>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );

  const getMachineControl = () => (
    <React.Fragment>
      <div className="col-auto px-1 d-flex flex-column">
        <label className="form-label" htmlFor="controlItemSelect">
          Machine
        </label>
        {machineRenderedAsRadio ? (
          <div className="form-check">
            {machines.map((input) => (
              <div key={input.id}>
                <input
                inputId={`${props.chartId}MachineRadBtn${input.id}`}
                  className="form-check-input"
                  type="radio"
                  name={`${props.chartId}MachineRadBtn`}
                  value={input.id}
                  onChange={handleMachineChange}
                  checked={machineId === input.id}
                  disabled={isLoading}
                />
                <label
                  className="form-check-label"
                  htmlFor={`${props.chartId}MachineRadBtn${input.id}`}
                >
                  {input.description}
                </label>
              </div>
            ))}
          </div>
        ) : (
          <select
            className="form-select"
            id={`${props.chartId}MachineDrpDnList`}
            name={`${props.chartId}MachineDrpDnList`}
            onChange={handleMachineChange}
            value={machineId || ""}
            disabled={isLoading}
          >
            {machines.map((option) => (
              <option key={option.id} value={option.id}>
                {option.description}
              </option>
            ))}
          </select>
        )}
      </div>
    </React.Fragment>
  );

  const getStartEndDateControls = () => (
    <React.Fragment>
      <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
        <label className="form-label">Start Date</label>
        <input
          type="datetime-local"
          id={props.chartId + "StartDateTime"}
          name={props.chartId + "StartDateTime"}
          defaultValue={startDate}
          onChange={handleStartDateChange}
          className="input-outline"
          disabled={isLoading}
        />
      </div>
      <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
        <label className="form-label">End Date</label>
        <input
          type="datetime-local"
          id={props.chartId + "EndDateTime"}
          name={props.chartId + "EndDateTime"}
          defaultValue={endDate}
          onChange={handleEndDateChange}
          className="input-outline"
          disabled={isLoading}
        />
      </div>
    </React.Fragment>
  );

  const getLoadButtonControl = () => (
    <React.Fragment>
        <div className="col-auto form-group my-4 ps-1 pt-1 pe-8 d-flex flex-column">
          <button
            type="button"
            className="btn btn-primary load-button"
            id={props.chartId + "chartAreaId"}
            onClick={handleLoadChart}
            disabled={isLoading}
          >
            <i
              className={
                isLoading ? "pi pi-spin pi-spinner" : "pi pi-chart-bar"
              }
            ></i>
            <span>{isLoading ? "Loading" : "Load"}</span>
          </button>
        </div>
    </React.Fragment>
  );
return (
  <div className="row">
    <div className="col">
      <div className="row justify-content-center">
        {getProcessControl()}
        {getMachineControl()}
        {getStartEndDateControls()}
        {getLoadButtonControl()}
      </div>
      {error && hasAttemptedFetch &&  (
         <div className="row mt-5 justify-content-md-center">
          <div className="col-6">
            <div className="card border-secondary">
              <div className="card-header bg-dark text-white">
                  {errorStatus === 204 && (
                    <h3>No Matching Results!</h3>
                  )}
                  {errorStatus === 500 && (
                    <h3>Data/export unavailable</h3>
                  )}
                  {errorStatus && ![204, 500].includes(errorStatus) && (
                    <h3>Unable to Pull Chart Data</h3>
                  )}
                  {flag === "N" && (
                    <h3>Unable to Pull Chart Data</h3>
                  )}
              </div>
              <div className="card-body card-body-error rounded-bottom">
                  {/* Conditional rendering based on error status */}
                  {errorStatus === 204 && (
                    <h5>There are no results found.</h5>
                  )}
                  {errorStatus && ![204].includes(errorStatus) && (
                    <>
                    <h5>Charts were not loaded due to...</h5>
                    <ul class="mb-0">
                    <li>
                      {errorMessage}
                    </li>
                  </ul>
                  </>
                  )}
                  {flag === "N" && (
                    <>
                    <h5>Charts were not loaded due to...</h5>
                    <ul class="mb-0">
                      {nFlagErrorMsg.map((error_msg, index) => (
                        <li key={index}>
                          {error_msg}
                        </li>
                      ))}   
                  </ul>
                  </>
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
      {!wasDataFetchingSuccessful && (
        <div className="row mt-8 my-5 justify-content-md-center">
          <div className="col">
            <ShowEffOpTimeChart
              chartData={chartData}
              chartForm={chartForm}
              targetEff={targetEfficiency}
              id={props.chartId}
            />
          </div>
        </div>
      )}
    </div>
  </div>
);
}

export default FormControlListSelector;

