import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "assets/scss/dashboard.scss";
import "assets/css/charts_form_controls.css";
import './import.css';
import React, { useState, useRef, useLayoutEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import "assets/css/grid_table.css";
import { getDataApi, saveApi, loadApi } from "services/appApi";
import { pecaPageRows, pecaRowsPerPageOptions } from "pages/common/envPECA";

const OPCServerProcessData  = () => {
  let emptyRow = {
            file_name: "",
            seq_num: "",
            interface_id: "",
            col1: "",
            col2: "",
            col3: "",
            col4: "",
            col5: "",
            col6: "",
            col7: "",
            col8: "",
            col9: null,
            col10: "",
            col11: "",
            col12: "",
            col13: "",
            col14: "",
            col15: "",
            col16: null,
            col17: null,
            col18: null,
            col19: null,
            col20: null,
            col21: null,
            col22: null,
            col23: null,
            col24: null,
            col25: null,
            col26: null,
            col27: null,
            col28: null,
            col29: null,
            col30: null,
            col31: null,
            col32: null,
            col33: null,
            col34: null,
            col35: null,
            col36: null,
            col37: null,
            col38: null,
            col39: null,
            col40: null,
            col41: null,
            col42: null,
            col43: null,
            col44: null,
            col45: null,
            col46: null,
            col47: null,
            col48: null,
            col49: null,
            col50: null,
            col51: null,
            col52: null,
            col53: null,
            col54: null,
            created_by: 0,
            creation_date: "",
            last_updated_by: "",
            last_update_date: ""
  };

  const [rows, setRows] = useState([]);
  const [rowDialog, setRowDialog] = useState(false);
  const [showproductDialog, setShowProductDialog] = useState(false);
  const [row, setRow] = useState(emptyRow);
  const [selectedRows, setSelectedRows] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const toast = useRef(null);
  const dt = useRef(null);
  const navigate = useNavigate();

  // Fetch Machines when the component mounts
  useLayoutEffect(() => {
    const fetchRows = async () => {
      try {
        setIsLoading(true);
        const response = await loadApi("", "/api/setup/opcIntfc/selRows");
        console.log("Fetched Data:", response.data);
        console.log(response.data[0]);
        console.log(response.data[1]);
        if (response.data.length > 0) {
          let successFlag = response.data[0];
          if (successFlag === "Y") {
            console.log("success=Y");
            setRows(response.data[1]);
            setIsLoading(false);
          } else {
            //setRows(null);
            setRows([]);
            setIsLoading(false);
            const errorInfo = response.data[2];
 
            // Set the error message and display the dialog
            setErrorMessage(errorInfo);
            setErrorDialogVisible(true);
          }
          //setSearch(response.data);
        } else {
          //setRows(null);
          setRows([]);
          setIsLoading(false);
        }

        //setRows(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchRows();
  }, []);


  const hideDialog = () => {
    setSubmitted(false);
    setRowDialog(false);
    setShowProductDialog(false);
    setErrorDialogVisible(false);
  };

  const showProduct = (row) => {
    setRow({ ...row });
    setShowProductDialog(true);
}


const exportCSV = () => {
  dt.current.exportCSV();
};
const getLoadingHeaderContents = () => {
  return (
    <React.Fragment>
      <div className="row d-flex justify-content-center m-5">
        <div className="col-auto">
          <div className="card shadow-sm border-primary">
            <div className="card-header bg-primary text-white">
              <h2 className="mb-0">
              OPC Server Process Data
              </h2>
            </div>
            <div className="card-body text-center">
              <h3>
                <i className="fa-duotone fa-fw fa-spin-pulse fa-loader me-3"></i>{" "}
                The page is loading...
              </h3>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}

const getLoadedHeaderContents = () => {
  return (
    <React.Fragment>
      <div className="header-container">
        <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
        <i>
           
          
          <span className="normal-font"> OPC Server Process Data </span><br/><br/>
          <div className="header-container p-1">
              <style>
              {`
                input::placeholder {
                  font-size: 17px;
                }
              `}
            </style>
          <span className="p-input-icon-right w-full md:w-auto"> 
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto text-sm p-3"
          style={{ fontSize: '17px' }} 
        />
      </span>
          </div>
       
          </i>
        </h2>
      </div>
    </React.Fragment>
  );
}


  const header = (
    <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
      {/* <span className="p-input-icon-right w-full md:w-auto">
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto"
        />
      </span> */}
       <div className="mt-3 md:mt-0 flex md:flex-1 justify-content-end">
        <Button
          icon="pi pi-upload"
          className="p-button-primary p-button-rounded"
          onClick={exportCSV}
          tooltip="Export"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>
    </div>
  );


  const readBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-primary"
          onClick={() => showProduct(rowData)}
          tooltip="View"
          tooltipOptions={{ position: "bottom" }}
        />
      </React.Fragment>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="col-12">
      {isLoading ? getLoadingHeaderContents() : getLoadedHeaderContents()}
      {!isLoading && (
      <div className="card">
        <div className="action-buttons">
            <button className="back-button" onClick={() => navigate("/AdminPages/OPCServerSetups/OPCServerImportData")}>
                <i className="fas fa-arrow-left"></i> Back to Upload file
            </button>
            {/* <button className="import-button" onClick={handleImport} disabled={importing}>
                {importing ? (
                    <>
                        <i className="fas fa-spinner fa-spin"></i> Importing... Row {currentRow || ""}
                    </>
                ) : (
                    <>
                        <i className="fas fa-file-import"></i> Import
                    </>
                            )}
            </button> */}
        </div>
        {/* <div className="text-3xl text-800 font-bold mb-4">OPC Server Process Data</div> */}
        <DataTable
          ref={dt}
          value={rows}
          scrollable scrollHeight="flex"
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          dataKey="interface_id"
          className="custom-datatable-border"
          tableStyle={{ minWidth: '50rem' }}
          paginator
          rows={pecaPageRows}
          rowsPerPageOptions={pecaRowsPerPageOptions}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} data"
          globalFilter={globalFilter}
          header={header}
          emptyMessage="No data found"
          stripedRows
          showGridlines
        >
          
          <Column body={readBodyTemplate} exportable={false}  style={{width:'0.75rem' }}></Column>
          <Column field="file_name" header="file_name" sortable></Column>
          <Column field="seq_num" header="seq_num" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="interface_id" header="interface_id" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col1" header="col1 " sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col2" header="col2" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col3" header="col3" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col4" header="col4" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col5" header="col5" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col6" header="col6" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col7" header="col7" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col8" header="col8" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col9" header="col9" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col10" header="col10" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col11" header="col11" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col12" header="col12" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col13" header="col13" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col14" header="col14" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col15" header="col15" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col16" header="col16" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col17" header="col17" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col18" header="col18" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col19" header="col19" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col20" header="col20" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col21" header="col21" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col22" header="col22" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col23" header="col23" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col24" header="col24" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col25" header="col25" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col26" header="col26" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col27" header="col27" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col28" header="col28" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col29" header="col29" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col30" header="col30" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col31" header="col31" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col32" header="col32" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col33" header="col33" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col34" header="col34" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col35" header="col35" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col36" header="col36" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col37" header="col37" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col38" header="col38" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col39" header="col39" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col40" header="col40" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col41" header="col41" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col42" header="col42" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col43" header="col43" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col44" header="col44" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col45" header="col45" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col46" header="col46" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col47" header="col47" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col48" header="col48" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col49" header="col49" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col50" header="col50" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col51" header="col51" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col52" header="col52" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col53" header="col53" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="col54" header="col54" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="created_by" header="created_by" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="creation_date" header="creation_date" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="last_updated_by" header="last_updated_by" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="last_update_date" header="last_update_date" sortable style={{whiteSpace: 'nowrap' }}></Column>
        </DataTable>
      </div>
        )}

      <Dialog visible={showproductDialog} style={{ width: '600px' }} header="OPC Server Process Data" modal className="p-fluid" onHide={hideDialog}>

                <label htmlFor="file_name">file_name</label>
                    <div className="box">
                        {row.file_name}
                    </div>

                <label htmlFor="seq_num">seq_num</label>
                    <div className="box">
                        {row.seq_num}
                    </div>

                <label htmlFor="interface_id">interface_id</label>
                    <div className="box">
                        {row.interface_id}
                    </div>

                <label htmlFor="col1">col1</label>
                    <div className="box">
                        {row.col1}
                    </div>

                <label htmlFor="col2">col2</label>
                    <div className="box">
                        {row.col2}
                    </div>

                <label htmlFor="col3">col3</label>
                    <div className="box">
                        {row.col3}
                    </div>

                <label htmlFor="col4">col4</label>
                    <div className="box">
                        {row.col4}
                    </div>

                <label htmlFor="col5">col5</label>
                    <div className="box">
                        {row.col5}
                    </div>

                <label htmlFor="col6">col6</label>
                    <div className="box">
                        {row.col6}
                    </div>

                <label htmlFor="col7">col7</label>
                    <div className="box">
                        {row.col7}
                    </div>
                <label htmlFor="col8">col8</label>
                    <div className="box">
                        {row.col8}
                    </div>

                <label htmlFor="col9">col9</label>
                    <div className="box">
                        {row.col9}
                    </div>

                <label htmlFor="col10">col10</label>
                    <div className="box">
                        {row.col10}
                    </div>

                <label htmlFor="col11">col11</label>
                    <div className="box">
                        {row.col11}
                    </div>

                <label htmlFor="col12">col12</label>
                    <div className="box">
                        {row.col12}
                    </div>

                <label htmlFor="col13">col13</label>
                    <div className="box">
                        {row.col13}
                    </div>

                <label htmlFor="col14">col14</label>
                    <div className="box">
                        {row.col14}
                    </div>

                <label htmlFor="col15">col15</label>
                    <div className="box">
                        {row.col15}
                    </div>

                <label htmlFor="col16">col16</label>
                    <div className="box">
                        {row.col16}
                    </div>

                <label htmlFor="col17">col17</label>
                    <div className="box">
                        {row.col17}
                    </div>

                <label htmlFor="col18">col18</label>
                    <div className="box">
                        {row.col18}
                    </div>

                <label htmlFor="col19">col19</label>
                    <div className="box">
                        {row.col19}
                    </div>

                <label htmlFor="col20">col20</label>
                    <div className="box">
                        {row.col20}
                    </div>

                <label htmlFor="col21">col21</label>
                    <div className="box">
                        {row.col21}
                    </div>

                <label htmlFor="col22">col22</label>
                    <div className="box">
                        {row.col22}
                    </div>

                <label htmlFor="col23">col23</label>
                    <div className="box">
                        {row.col23}
                    </div>

                <label htmlFor="col24">col24</label>
                    <div className="box">
                        {row.col24}
                    </div>

                <label htmlFor="col25">col25</label>
                    <div className="box">
                        {row.col25}
                    </div>

                <label htmlFor="col26">col26</label>
                    <div className="box">
                        {row.col26}
                    </div>

                <label htmlFor="col27">col27</label>
                    <div className="box">
                        {row.col27}
                    </div>

                <label htmlFor="col28">col28</label>
                    <div className="box">
                        {row.col28}
                    </div>

                <label htmlFor="col29">col29</label>
                    <div className="box">
                        {row.col29}
                    </div>

                <label htmlFor="col30">col30</label>
                    <div className="box">
                        {row.col30}
                    </div>

                <label htmlFor="col31">col31</label>
                    <div className="box">
                        {row.col31}
                    </div>

                <label htmlFor="col32">col32</label>
                    <div className="box">
                        {row.col32}
                    </div>

                <label htmlFor="col33">col33</label>
                    <div className="box">
                        {row.col33}
                    </div>

                <label htmlFor="col34">col34</label>
                    <div className="box">
                        {row.col34}
                    </div>

                <label htmlFor="col35">col35</label>
                    <div className="box">
                        {row.col35}
                    </div>

                <label htmlFor="col36">col36</label>
                    <div className="box">
                        {row.col36}
                    </div>

                <label htmlFor="col37">col37</label>
                    <div className="box">
                        {row.col37}
                    </div>

                <label htmlFor="col38">col38</label>
                    <div className="box">
                        {row.col38}
                    </div>

                <label htmlFor="col39">col39</label>
                    <div className="box">
                        {row.col39}
                    </div>

                <label htmlFor="col40">col40</label>
                    <div className="box">
                        {row.col40}
                    </div>

                <label htmlFor="col41">col41</label>
                    <div className="box">
                        {row.col41}
                    </div>

                <label htmlFor="col42">col42</label>
                    <div className="box">
                        {row.col42}
                    </div>

                <label htmlFor="col43">col43</label>
                    <div className="box">
                        {row.col43}
                    </div>

                <label htmlFor="col44">col44</label>
                    <div className="box">
                        {row.col44}
                    </div>

                <label htmlFor="col45">col45</label>
                    <div className="box">
                        {row.col45}
                    </div>

                <label htmlFor="col46">col46</label>
                    <div className="box">
                        {row.col46}
                    </div>

                <label htmlFor="col47">col47</label>
                    <div className="box">
                        {row.col47}
                    </div>

                <label htmlFor="col48">col48</label>
                    <div className="box">
                        {row.col48}
                    </div>

                <label htmlFor="col49">col49</label>
                    <div className="box">
                        {row.col49}
                    </div>

                <label htmlFor="col50">col50</label>
                    <div className="box">
                        {row.col50}
                    </div>

                <label htmlFor="col51">col51</label>
                    <div className="box">
                        {row.col51}
                    </div>

                <label htmlFor="col52">col52</label>
                    <div className="box">
                        {row.col52}
                    </div>

                <label htmlFor="col53">col53</label>
                    <div className="box">
                        {row.col53}
                    </div>

                <label htmlFor="col54">col54</label>
                    <div className="box">
                        {row.col54}
                    </div>

                <label htmlFor="created_by">created_by</label>
                    <div className="box">
                        {row.created_by}
                    </div>

                <label htmlFor="creation_date">creation_date</label>
                    <div className="box">
                        {row.creation_date}
                    </div>

                <label htmlFor="last_updated_by">last_updated_by</label>
                    <div className="box">
                        {row.last_updated_by}
                    </div>

                    <label htmlFor="last_update_date">last_update_date</label>
                <div className="box">
                        {row.last_update_date}
                    </div>

            </Dialog>
            <Dialog 
                header="API Error" 
                visible={errorDialogVisible} 
                style={{ width: '400px' }} 
                onHide={hideDialog} 
                footer={
                    <Button label="OK" onClick={hideDialog} className="p-button-primary p-button-rounded" />
                }
            >    
             <p>

                    {errorMessage}
                </p>
            </Dialog>
            </div>
    </div>
  );
};

export default OPCServerProcessData;


