import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "assets/scss/dashboard.scss";
import "assets/css/charts_form_controls.css";
import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import "assets/css/grid_table.css";
import { getDataApi, saveApi, loadApi } from "services/appApi";
import { pecaPageRows, pecaRowsPerPageOptions } from "pages/common/envPECA";

const DxpDataLoggers  = () => {
  let emptyRow = {
    logging_id: null,
    group_name: "",
    file_name: "",
    logging_cycle: 1,
    b_time_log: 0,
    use_base_time: 0,
    data_change_logging: 1,
    b_end_data_logging: 0,
    log_db_type: "",
    s_logging_path: "",
    odbc_uid: null,
    odbc_pwd: null,
    disk_io_byte: 0,
    disk_io_count: 0,
    triger_tag: null,
    request_tag: null,
    reply_tag: null,
    report_type: 0,
    report_base_month: 1,
    csv_div_count: 0,
    csv_div_mid_time: 0,
    csv_div_min_cycle: 60,
    csv_div_triger: 0,
    csv_name_rule: 0,
    prefix: null,
    suffix: null,
    strage_period: 0,
    csv_div_start: 0,
    buffer_count: 30000,
    start_enable: 1,
    csv_div_tag: 0,
    dynamic_cycle_tag: null,
    use_update: 0,
    unit_insert: 1,
    csv_div_update_date: 0,
    csv_div_time2: 0,
    csv_div_time3: 0,
    svdomain: null,
    svuid: null,
    svpwd: null,
    disk_iotime_out: "",
  };

  const [rows, setRows] = useState([]);
  const [rowDialog, setRowDialog] = useState(false);
  const [showproductDialog, setShowProductDialog] = useState(false);
  const [row, setRow] = useState(emptyRow);
  const [selectedRows, setSelectedRows] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [globalFilter1, setGlobalFilter1] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [expandedRowId, setExpandedRowId] = useState([]);
  const [loggings, setLoggings] = useState({}); 
  const toast = useRef(null);
  const dt = useRef(null);
  const dt1 = useRef(null);

  // Fetch Machines when the component mounts
  useLayoutEffect(() => {
    const fetchRows = async () => {
      try {
        setIsLoading(true);
        const response = await getDataApi("/api/setup/dxpLoggings/selRows");
        console.log("Fetched Data:", response.data);
        console.log(response.data[0]);
        console.log(response.data[1]);
        if (response.data.length > 0) {
          let successFlag = response.data[0];
          if (successFlag === "Y") {
            console.log("success=Y");
            setRows(response.data[1]);
            setIsLoading(false);
          } else {
            //setRows(null);
            setRows([]);
            setIsLoading(false);
            const errorInfo = response.data[2];

            // Set the error message and display the dialog
            setErrorMessage(errorInfo);
            setErrorDialogVisible(true);
          }
          //setSearch(response.data);
        } else {
          //setRows(null);
          setRows([]);
          setIsLoading(false);
        }

        //setRows(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchRows();
  }, []);
  


  const hideDialog = () => {
    setSubmitted(false);
    setRowDialog(false);
    setShowProductDialog(false);
    setErrorDialogVisible(false);
  };

  const showProduct = (row) => {
    setRow({ ...row });
    setShowProductDialog(true);
}


const exportCSV = () => {
  dt.current.exportCSV();
};
const getLoadingHeaderContents = () => {
  return (
    <React.Fragment>
      <div className="row d-flex justify-content-center m-5">
        <div className="col-auto">
          <div className="card shadow-sm border-primary">
            <div className="card-header bg-primary text-white">
              <h2 className="mb-0">
              DXP Data Logging Groups
              </h2>
            </div>
            <div className="card-body text-center">
              <h3>
                <i className="fa-duotone fa-fw fa-spin-pulse fa-loader me-3"></i>{" "}
                The page is loading...
              </h3>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}
const getLoadedHeaderContents = () => {
  return (
    <React.Fragment>
      <div className="header-container">
        <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
        <i>
           
          
          <span className="normal-font"> DXP Data Logging Groups </span><br/><br/>
          <div className="header-container p-1">
              <style>
              {`
                input::placeholder {
                  font-size: 17px; /* Adjust placeholder size */
                }
              `}
            </style>
          <span className="p-input-icon-right w-full md:w-auto"> 
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto text-sm p-3"
          style={{ fontSize: '17px' }} 
        />
      </span>
          </div>
       
          </i>
        </h2>
      </div>
    </React.Fragment>
  );
}

const formatBTimeLog = (rowData) => {
  return rowData.b_time_log === 0 ? "No" : "Yes";
};
const formatENDBTimeLog = (rowData) => {
  return rowData.b_end_data_logging === 0 ? "No" : "Yes";
};
const formatBTimeLog1 = row.b_time_log === 0 ? "No" : "Yes";
const formatENDBTimeLog1 = row.b_end_data_logging === 0 ? "No" : "Yes";
  const header = (
    <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
      {/* <span className="p-input-icon-right w-full md:w-auto">
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto"
        />
      </span> */}
      <div className="mt-3 md:mt-0 flex md:flex-1 justify-content-end">
        <Button
          icon="pi pi-upload"
          className="p-button-primary p-button-rounded"
          onClick={exportCSV}
          tooltip="Export"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>
    </div>
  );
  


  const readBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-primary"
          onClick={() => showProduct(rowData)}
          tooltip="View"
          tooltipOptions={{ position: "bottom" }}
        />
      </React.Fragment>
    );
  };
  const fetchLoggings = async (logging_id) => {
    const p_param = {
        p_logging_id: logging_id
    };
    console.log(p_param);
    try {
        const response = await loadApi(p_param, "/api/setup/dxpLoggingDtls/selRows");
        console.log("Fetched Loggings:", response.data);

        if (response.data.length > 0) {
            const successFlag = response.data[0];
            if (successFlag === "Y") {
                setLoggings(prevLoggings => ({ ...prevLoggings, [logging_id]: response.data[1] }));
            } else {
                setLoggings(prevLoggings => ({ ...prevLoggings, [logging_id]: [] }));
            }
        } else {
            setLoggings(prevLoggings => ({ ...prevLoggings, [logging_id]: [] }));
        }
    } catch (error) {
        console.error("Error fetching loggings:", error);
    } finally {
        setIsLoading(false);
    }
};

useEffect(() => {
  expandedRowId.forEach(logging_id => fetchLoggings(logging_id));
}, [expandedRowId]);

const drillDownTemplate = (rowData) => {
  const isExpanded = expandedRowId.includes(rowData.logging_id);

  return (
      <Button
          icon={isExpanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'}
          className={`p-button-rounded p-button-sm ${isExpanded ? 'p-button-danger' : 'p-button-primary'}`}
          onClick={() => toggleRowExpansion(rowData.logging_id)}
          tooltip="Detail"
          tooltipOptions={{ position: "bottom" }}
          style={{ fontSize: '1rem' }} 
      />
  );
};


const toggleRowExpansion = (rowId) => {
  setExpandedRowId(prevExpandedRowId => 
      prevExpandedRowId.includes(rowId) 
          ? prevExpandedRowId.filter(id => id !== rowId) 
          : [...prevExpandedRowId, rowId]
  );
};

const rowExpansionTemplate = (data) => {
    const loggingData = loggings[data.logging_id] || [];
    const exportCSV1 = () => {
      dt1.current.exportCSV();;
    };

    return (
        <div className="p-3">
          <div className="header-container">
            <div className="header-container p-1">
                <style>
                {`
                  input::placeholder {
                    font-size: 17px; 
                  }
                `}
              </style>
            <span className="p-input-icon-right w-full md:w-auto"> 
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter1(e.target.value)}
            placeholder="Search..."
            className="w-full lg:w-auto text-sm p-3"
            style={{ fontSize: '17px' }} 
          />
        </span>
        <Button
          icon="pi pi-upload"
          className="p-button-primary p-button-rounded"
          onClick={exportCSV1}
          tooltip="Export"
          tooltipOptions={{ position: "bottom" }}
          style={{ marginLeft: "20px" }}
        />
            </div>
         
           
        </div>
          <DataTable ref={dt1} value={loggingData} responsiveLayout="scroll" globalFilter={globalFilter1}>
          <Column field="group_name" header="Group Name" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="file_name" header="File Name" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="logging_path" header="File Path" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="field_name" header="Field Name" sortable style={{whiteSpace: 'nowrap' }} ></Column>
          <Column field="field_item_type" header="Item Type" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="tag_name" header="Tag Name" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="field_data_type" header="File Data Type" sortable></Column>
                </DataTable>
        </div>
    );
};


  return (
   
      <div>
      <Toast ref={toast} />
      <div className="col-12">
      {isLoading ? getLoadingHeaderContents() : getLoadedHeaderContents()}
      {!isLoading && (
      <div className="card">
        {/* <div className="text-3xl text-800 font-bold mb-4">DXP Data Logger</div> */}
        <DataTable
          ref={dt}
          value={rows}
          scrollable scrollHeight="flex"
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          dataKey="logging_id"
          className="custom-datatable-border"
          tableStyle={{ minWidth: '50rem' }}
          paginator
          rows={pecaPageRows}
          rowsPerPageOptions={pecaRowsPerPageOptions}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} data"
          globalFilter={globalFilter}
          header={header}
          emptyMessage="No data found"
          stripedRows
          showGridlines
          expandedRows={expandedRowId.map(logging_id => rows.find(rows => rows.logging_id === logging_id))}
          onRowToggle={(e) => {
              const rowId = e.data ? e.data.logging_id : null;
              setExpandedRowId(prev => 
              prev.includes(rowId) 
              ? prev.filter(logging_id => logging_id !== rowId) 
              : [...prev, rowId]
              );
            }}
          rowExpansionTemplate={rowExpansionTemplate}
          responsiveLayout="scroll"
          rowExpansion
        >
         
          <Column body={readBodyTemplate} exportable={false}> style={{width:'0.75rem' }}</Column>
          <Column body={drillDownTemplate} style={{ width: '3rem', textAlign: 'center' }} />
          <Column field="group_name" header="Group Name" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="file_name" header="File Name" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="logging_cycle" header="Event Cycle (Sec)" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="b_time_log" header="Time Logging" sortable body={(rowData) => formatBTimeLog(rowData)} style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="b_end_data_logging" header="Stop Data Logging" sortable body={(rowData) => formatENDBTimeLog(rowData)} style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="s_logging_path" header="File Path" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="csv_div_min_cycle" header="File Fixed Cycle(Min)" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="csv_name_rule" header="File Name Rule" sortable style={{whiteSpace: 'nowrap' }}></Column>
        </DataTable>
      </div>
       )}

      <Dialog visible={showproductDialog} style={{ width: '600px' }} header="DXP Data Logging Groups" modal className="p-fluid" onHide={hideDialog}>
                
                <label htmlFor="group_name">Group Name</label>
                    <div className="box">
                        {row.group_name}
                    </div>

                <label htmlFor="file_name">File Name</label>
                    <div className="box">
                        {row.file_name}
                    </div>

                <label htmlFor="logging_cycle">Event Cycle (Sec)</label>
                    <div className="box">
                        {row.logging_cycle}
                    </div>

                <label htmlFor="b_time_log">Time Logging</label>
                    <div className="box">
                        {formatBTimeLog1}
                    </div>

                <label htmlFor="b_end_data_logging">Stop Data Logging</label>
                    <div className="box">
                        {formatENDBTimeLog1}
                    </div>

                <label htmlFor="s_logging_path">File Path</label>
                    <div className="box">
                        {row.s_logging_path}
                    </div>

                <label htmlFor="csv_div_min_cycle">File Fixed Cycle(Min)</label>
                    <div className="box">
                        {row.csv_div_min_cycle}
                    </div>

                <label htmlFor="csv_name_rule">File Name Rule</label>
                    <div className="box">
                        {row.csv_name_rule}
                    </div>

            </Dialog>
              <Dialog 
                header="API Error" 
                visible={errorDialogVisible} 
                style={{ width: '400px' }} 
                onHide={hideDialog} 
                footer={
                    <Button label="OK" onClick={hideDialog} className="p-button-primary p-button-rounded" />
                }
            >    
             <p>

                    {errorMessage}
                </p>
            </Dialog>
      </div>
    </div>
  );
};

export default DxpDataLoggers;

