import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "assets/scss/dashboard.scss";
import "assets/css/charts_form_controls.css";
import "assets/css/grid_table.css";
import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { getDataApi, saveApi, loadApi } from "services/appApi";
import { pecaPageRows, pecaRowsPerPageOptions } from "pages/common/envPECA";

const PollMachErrors = () => {
  const emptyRow = {
    opc_machine_name: "",
    machine_name: "",
    dxp_file_prefix: "",
    remote_dir_file_pattern: "",
    error_message: "",
    creation_date: "",
    poll_data: null,
  };

  const [rows, setRows] = useState([]);
  const [rowDialog, setRowDialog] = useState(false);
  const [showproductDialog, setShowProductDialog] = useState(false);
  const [row, setRow] = useState(emptyRow);
  const [selectedRows, setSelectedRows] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingErrors, setLoadingErrors] = useState([]);
  const [wasDataFetchingSuccessful, setWasDataFetchingSuccessful] =
    useState(true);

  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const yesterday = moment().subtract(1, "days").set({
        hour: 7,
        minute: 0,
        second: 0,
        millisecond: 0,
      });

      let formattedStartDate = yesterday.format("yyyy-MM-DD HH:mm");

      const tomorrow = moment().add(0, "days").set({
        hour: 6,
        minute: 59,
        second: 0,
        millisecond: 0,
      });
      let formattedEndDate = tomorrow.format("yyyy-MM-DD HH:mm");
    };

    fetchData();
  }, []);

  useLayoutEffect(() => {
    const fetchRows = async () => {
      try {
        setIsLoading(true);
        const response = await loadApi("", "/api/txn/getMachLoggingErrors");
        console.log("Fetched Data:", response.data);
        console.log(response.data[0]);
        console.log(response.data[1]);
        if (response.data.length > 0) {
          let successFlag = response.data[0];
          if (successFlag === "Y") {
            console.log("success=Y");
            setRows(response.data[1]);
            setIsLoading(false);
            setWasDataFetchingSuccessful(false);
          } else {
            //setRows(null);
            setRows([]);
            setIsLoading(false);
            setWasDataFetchingSuccessful(true);
            const errorInfo = response.data[2];
            // Set the error message and display the dialog
            setErrorMessage(errorInfo);
            setErrorDialogVisible(true);
          }
          //setSearch(response.data);
        } else {
          //setRows(null);
          setRows([]);
          setIsLoading(false);
          setWasDataFetchingSuccessful(true);
        }

        //setRows(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchRows();
  }, []);

  const hideDialog = () => {
    setSubmitted(false);
    setRowDialog(false);
    setErrorDialogVisible(false);
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const header = (
    <div className="flex justify-content-between ">
      <div style={{ width: "800px" }}></div>
      <span className="p-input-icon-right align-content-center">
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search ..."
          style={{ width: "600px" }}
          tooltip="Search"
          tooltipOptions={{ position: "bottom" }}
        />
      </span>
      <div className="mt-3 md:mt-0 flex md:flex-1 justify-content-end">
        <Button
          icon="pi pi-upload"
          className="p-button-primary p-button-rounded"
          onClick={exportCSV}
          tooltip="Export"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>
    </div>
  );

  const showProduct = (row) => {
    setRow({ ...row });
    setRowDialog(true);
  };

  const readBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-primary"
          onClick={() => showProduct(rowData)}
          tooltip="View"
          tooltipOptions={{ position: "bottom" }}
        />
      </React.Fragment>
    );
  };

  const getLoadingHeaderContents = () => {
    return (
      <React.Fragment>
        <div className="row d-flex justify-content-center m-5">
          <div className="col-auto">
            <div className="card shadow-sm border-primary">
              <div className="card-header bg-primary text-white">
                <h2 className="mb-0">Device Explorer Logging Setting Error</h2>
              </div>
              <div className="card-body text-center">
                <h3>
                  <i className="fa-duotone fa-fw fa-spin-pulse fa-loader me-3"></i>{" "}
                  The page is loading...
                </h3>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
  const getErrrorMsgContents = () => {
    return (
      <React.Fragment>
        <div class="row mt-5 justify-content-md-center">
          <div class="col-3">
            <div class="card border-secondary">
              <div class="card-header bg-dark text-white">
                DXP Poll Data Errors table is Unavailable
              </div>
              <div class="card-body card-body-error rounded-bottom">
                <ul>
                  {loadingErrors.map((error) => (
                    <li>error</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const getLoadedHeaderContents = () => {
    return (
      <React.Fragment>
        <div className="header-container">
          <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
            <i>
              <span className="normal-font">
                Device Explorer Logging Setting Error{" "}
              </span>
              <br />
              <br />
            </i>
          </h2>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div>
      <div className="col-12">
        {getLoadedHeaderContents()}
        {loadingErrors.length > 0 && getErrrorMsgContents()}
        <div className="row">
          <div className="col">
            <div className="row justify-content-center">
              {/* {getSearchControls()} */}
            </div>
            {!wasDataFetchingSuccessful && (
              <div className="card">
                {/* <div className="text-3xl text-800 font-bold mb-4">Dxp Poll Data Errors</div> */}
                <Toast ref={toast} />
                <DataTable
                  ref={dt}
                  value={rows}
                  scrollable
                  scrollHeight="flex"
                  dataKey="poll_data_intfc_id"
                  className="custom-datatable-border"
                  tableStyle={{ minWidth: "50rem" }}
                  paginator
                  rows={pecaPageRows}
                  rowsPerPageOptions={pecaRowsPerPageOptions}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} data"
                  globalFilter={globalFilter}
                  header={header}
                  emptyMessage="No data found"
                  stripedRows
                  showGridlines
                >
                  <Column
                    body={readBodyTemplate}
                    exportable={false}
                    style={{ width: "0.75rem" }}
                  ></Column>
                  <Column
                    field="opc_machine_name"
                    header="OPC Machine Name"
                    sortable
                    // body={formatCreationDate}
                    style={{ whiteSpace: "nowrap" }}
                  />
                  <Column
                    field="machine_name"
                    header="Machine Name"
                    sortable
                    style={{ whiteSpace: "nowrap" }}
                  />
                  <Column
                    field="dxp_file_prefix"
                    header="File Name"
                    sortable
                    style={{ whiteSpace: "nowrap" }}
                  />
                  <Column
                    field="remote_dir_file_pattern"
                    header="Remote Dir File Pattern"
                    sortable
                    style={{ whiteSpace: "nowrap" }}
                  />
                  <Column
                    field="error_message"
                    header="Error Message"
                    sortable
                    style={{ whiteSpace: "nowrap" }}
                  />
                  <Column
                    field="creation_date"
                    header="Creation Date"
                    sortable
                    style={{ whiteSpace: "nowrap" }}
                  />
                  <Column
                    field="poll_data"
                    header="PollData"
                    sortable
                    style={{ whiteSpace: "nowrap" }}
                  />
                </DataTable>

                <Dialog
                  visible={rowDialog}
                  style={{ width: "600px" }}
                  header="Device Explorer Logging Setting Error Details"
                  modal
                  onHide={hideDialog}
                >
                  <label htmlFor="opc_machine_name">OPC Machine Name</label>
                  <div className="box">{row.opc_machine_name}</div>

                  <label htmlFor="machine_name">Machine Name</label>
                  <div className="box">{row.machine_name}</div>

                  <label htmlFor="dxp_file_prefix">File Name</label>
                  <div className="box">{row.dxp_file_prefix}</div>

                  <label htmlFor="remote_dir_file_pattern">
                    Remote File Pattern
                  </label>
                  <div className="box">{row.remote_dir_file_pattern}</div>
                  <label htmlFor="error_message">Error Message</label>
                  <div className="box">{row.error_message}</div>
                  <label htmlFor="creation_date">Creation Date</label>
                  <div className="box">{row.creation_date}</div>

                  <label htmlFor="poll_data">Poll Data</label>
                  <div className="box">{row.poll_data}</div>
                </Dialog>

                <Dialog
                  header="API Error"
                  visible={errorDialogVisible}
                  style={{ width: "400px" }}
                  onHide={hideDialog}
                  footer={
                    <Button
                      label="OK"
                      onClick={hideDialog}
                      className="p-button-primary p-button-rounded"
                    />
                  }
                >
                  <p>{errorMessage}</p>
                </Dialog>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PollMachErrors;
