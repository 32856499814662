import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "assets/scss/dashboard.scss";
import "assets/css/charts_form_controls.css";
import "./systemOptions.css"
import moment from "moment";
import numeral from 'numeral';
import React, { useState, useRef, useLayoutEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import "assets/css/grid_table.css";
import { getDataApi, saveApi, loadApi } from "services/appApi";
import { pecaPageRows, pecaRowsPerPageOptions } from "pages/common/envPECA";
import { Tooltip } from "primereact/tooltip";

const SystemOptions  = () => {
  let emptyRow = {
    organization_id: "",
    organization_code: "",
    allow_negative_inventory_mean: "",
    intransit_subinventory_code: "",
    default_ht_aging_hours: "",
    default_rt_aging_hours: "",
    auto_start_mg_shift_hrs_bef: "",
    auto_end_mg_shift_hrs_aft: "",
    tpa_default_day_running_time: "",
    pdcs_start_eff_date: "",
    conc_pgm_pdcs_user_name: "",
    conc_pgm_rpt_user_name: "",
    conc_pgm_rpt_resp_name: "",
    conc_pgm_rpt_resp_appl_name: "",
    log_prefix_name: "",
    enable_db_log_meaning: "",
    default_tpa_bag_quantity: "",
    max_tpa_bag_quantity: "",
    keep_plc_poll_hist_days: "",
    no_of_purge_days: "",
    commit_batch_size: "",
    poll_data_arch_host_name: "",
    poll_data_arch_port_number: "",
    poll_data_arch_ftp_user: "",
    poll_data_arch_ftp_password: "",
    poll_data_dxp_host_name: "",
    poll_data_dxp_port_number: "",
    poll_data_dxp_ftp_user: "",
    poll_data_dxp_ftp_password: "",
    poll_data_dxp_remote_base_dir: "",
    poll_data_dxp_local_base_dir: ""
  };

  const [rows, setRows] = useState([]);
  const [rowDialog, setRowDialog] = useState(false);
  const [showproductDialog, setShowProductDialog] = useState(false);
  const [row, setRow] = useState(emptyRow);
  const [selectedRows, setSelectedRows] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [visiblePassword, setVisiblePassowrd] = useState({});
  const [isPasswordVisble, setIsPasswordVisible] = useState(false);
  const [isPasswordVisble1, setIsPasswordVisible1] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const p_param = {
    p_file_id:null,
    p_file_name:null,
    p_process_id:null,
    p_line_number:null,
    p_machine_id:null,
    p_start_datetime:null,
    p_end_datetime:null,
    p_machine_name_like:null,
  };
 
  // Fetch Machines when the component mounts
  useLayoutEffect(() => {
    const fetchRows = async () => {
      try {
        setIsLoading(true);
        const response = await loadApi(p_param,"/api/setup/systemOptions/selRows");
        console.log("Fetched Data:", response.data);
        console.log(response.data[0]);
        console.log(response.data[1]);
        if (response.data.length > 0) {
          let successFlag = response.data[0];
          if (successFlag === "Y") {
            console.log("success=Y");
            setRows(response.data[1]);
            setIsLoading(false);
          } else {
            //setRows(null);
            setRows([]);
            setIsLoading(false);
            const errorInfo = response.data[2];

            // Set the error message and display the dialog
            setErrorMessage(errorInfo);
            setErrorDialogVisible(true);
          }
          //setSearch(response.data);
        } else {
          //setRows(null);
          setRows([]);
          setIsLoading(false);
        }

        //setRows(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchRows();
  }, []);


  const hideDialog = () => {
    setSubmitted(false);
    setRowDialog(false);
    setShowProductDialog(false);
    setErrorDialogVisible(false);
  };

  const showProduct = (row) => {
    setRow({ ...row });
    setShowProductDialog(true);
}

const exportCSV = () => {
  dt.current.exportCSV();
};

const getLoadingHeaderContents = () => {
  return (
    <React.Fragment>
      <div className="row d-flex justify-content-center m-5">
        <div className="col-auto">
          <div className="card shadow-sm border-primary">
            <div className="card-header bg-primary text-white">
              <h2 className="mb-0">
              System Options
              </h2>
            </div>
            <div className="card-body text-center">
              <h3>
                <i className="fa-duotone fa-fw fa-spin-pulse fa-loader me-3"></i>{" "}
                The page is loading...
              </h3>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}
const getLoadedHeaderContents = () => {
  return (
    <React.Fragment>
      <div className="header-container">
        <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
        <i>
           
          
          <span className="normal-font"> System Options </span><br/><br/>
          <div className="header-container p-1">
              <style>
              {`
                input::placeholder {
                  font-size: 17px;
                }
              `}
            </style>
          <span className="p-input-icon-right w-full md:w-auto"> 
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto text-sm p-3"
          style={{ fontSize: '17px' }} 
        />
      </span>
          </div>
       
          </i>
        </h2>
      </div>
    </React.Fragment>
  );
}

  const header = (
    <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
      {/* <span className="p-input-icon-right w-full md:w-auto">
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto"
        />
      </span> */}
      <div className="mt-3 md:mt-0 flex md:flex-1 justify-content-end">
        <Button
          icon="pi pi-upload"
          className="p-button-primary p-button-rounded"
          onClick={exportCSV}
          tooltip="Export"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>
    </div>
  );

  const HTTemplate = (rowData) => {
    return numeral(rowData.default_ht_aging_hours).format('0,0');
  }
  const RTTemplate = (rowData) => {
    return numeral(rowData.default_rt_aging_hours).format('0,0');
  }
  const MGBeforeTemplate = (rowData) => {
    return numeral(rowData.auto_start_mg_shift_hrs_bef).format('0,0');
  }
  const MGAfterTemplate = (rowData) => {
    return numeral(rowData.auto_end_mg_shift_hrs_aft).format('0,0');
  }
  const TPABagTemplate = (rowData) => {
    return numeral(rowData.default_tpa_bag_quantity).format('0,0');
  }
  const MaxTPABagTemplate = (rowData) => {
    return numeral(rowData.max_tpa_bag_quantity).format('0,0');
  }
  const PollHistTemplate = (rowData) => {
    return numeral(rowData.keep_plc_poll_hist_days).format('0,0');
  }
  const NoOfDaysTemplate = (rowData) => {
    return numeral(rowData.no_of_purge_days).format('0,0');
  }
  const CommitTemplate = (rowData) => {
    return numeral(rowData.commit_batch_size).format('0,0');
  }

  const HTNumber = numeral(row.default_ht_aging_hours).format('0,0');
  const RTNumber = numeral(row.default_rt_aging_hours).format('0,0');
  const MGBeforeNumber = numeral(row.auto_start_mg_shift_hrs_bef).format('0,0');
  const MGAfterNumber = numeral(row.auto_end_mg_shift_hrs_aft).format('0,0');
  const TPABagNumber = numeral(row.default_tpa_bag_quantity).format('0,0');
  const MaxTPABagNumber = numeral(row.max_tpa_bag_quantity).format('0,0');
  const PollHistNumber = numeral(row.keep_plc_poll_hist_days).format('0,0');
  const NoOfDaysNumber = numeral(row.no_of_purge_days).format('0,0');
  const CommitNumber = numeral(row.commit_batch_size).format('0,0');

  const getMaskedValue = (value) => '*'.repeat(value.length);
  // const togglePasswordVisibility = () =>setIsPasswordVisible((prev) => !prev);
  // const togglePasswordVisibility1 = () =>setIsPasswordVisible1((prev) => !prev);
  const showPassword = (id, passwordType) => {
    setVisiblePassowrd((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [passwordType]: true,
      }
    }))
  }
  const hidePassword = (id, passwordType) => {
    setVisiblePassowrd((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [passwordType]: false,
      }
    }))
  }
  const PasswordTemplate = (rowData, passwordType) => {
    const PasswordVisble = visiblePassword[rowData.organization_id]?.[passwordType] || false;
    return (
      <span onMouseDown={() => showPassword(rowData.organization_id, passwordType)} onMouseUp={() => hidePassword(rowData.organization_id, passwordType)} onMouseLeave={() => hidePassword(rowData.organization_id, passwordType)}>
        {PasswordVisble ? rowData[passwordType] : getMaskedValue(rowData[passwordType])}
      </span>
    )
  }

  const readBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-primary"
          onClick={() => showProduct(rowData)}
          tooltip="View"
          tooltipOptions={{ position: "bottom" }}
        />
      </React.Fragment>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="col-12">
      {isLoading ? getLoadingHeaderContents() : getLoadedHeaderContents()}
      {!isLoading && (
      <div className="card">
        {/* <div className="text-3xl text-800 font-bold mb-4">System Options</div> */}
        <Tooltip target=".keep_plc_poll_hist_days-header-tooltip" content="How many days of legacy poll history to be kept (in zzpdc_plc_polling_intf_hist)" position="top"/>
        <Tooltip target=".no_of_purge_days-header-tooltip" content="Max Num of days of data to purge in single program run" position="top"/>
        <Tooltip target=".commit_batch_size-header-tooltip" content="Used for purging data from zzpdc_plc_polling_intf_hist" position="top"/>
        <Tooltip target=".poll_data_arch_host_name-header-tooltip" content="The archive text files are stored in this server (generally a Windows Server)" position="top"/>
        <Tooltip target=".poll_data_dxp_host_name-header-tooltip" content="The server where the Poll Data csv files are stored." position="top"/>
        <Tooltip target=".poll_data_dxp_remote_base_dir-header-tooltip" content="Base directory underneath subfolders suchas 2024/11 are available." position="top"/>
        <Tooltip target=".poll_data_dxp_local_base_dir-header-tooltip" content="This is Oracle Directory such as ZZPDC_OUT_DIR" position="top"/>
        <DataTable
          ref={dt}
          value={rows}
          scrollable scrollHeight="flex"     
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          dataKey="file_id"
          className="custom-datatable-border"
          tableStyle={{ minWidth: '50rem' }}
          paginator
          rows={pecaPageRows}
          rowsPerPageOptions={pecaRowsPerPageOptions}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} data"
          globalFilter={globalFilter}
          header={header}
          emptyMessage="No data found"
          stripedRows
          showGridlines
        >
    
          <Column body={readBodyTemplate} exportable={false} style={{width:'0.75rem' }}></Column>
          <Column field="organization_code" header="Organization Code" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="allow_negative_inventory_mean" header="Allow Negative Inventory?" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="intransit_subinventory_code" header="Intrasit Subinventory" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="default_ht_aging_hours" header="Default HT Aging Hours" body={HTTemplate} sortable style={{whiteSpace: 'nowrap', textAlign: 'right' }}></Column>
          <Column field="default_rt_aging_hours" header="Default RT Aging Hours" body={RTTemplate} sortable style={{whiteSpace: 'nowrap', textAlign: 'right' }}></Column>
          <Column field="auto_start_mg_shift_hrs_bef" header="Auto Start MG Shift Hours Before" body={MGBeforeTemplate} sortable style={{whiteSpace: 'nowrap', textAlign: 'right' }}></Column>
          <Column field="auto_end_mg_shift_hrs_aft" header="Auto End MG Shift Hours After" body={MGAfterTemplate} sortable style={{whiteSpace: 'nowrap', textAlign: 'right' }}></Column>
          <Column field="tpa_default_day_running_time" header="TPA Default Day Running Time" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="pdcs_start_eff_date" header="PDCS Start Eff Date" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="conc_pgm_pdcs_user_name" header="Conc Pgm PDCS User" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="conc_pgm_rpt_user_name" header="Conc Rpt Submit User" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="conc_pgm_rpt_resp_name" header="Conc Rpt Submit Resp" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="conc_pgm_rpt_resp_appl_name" header="Conc Rpt Submit Resp App" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="log_prefix_name" header="Log Prefix Name" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="enable_db_log_meaning" header="Enable DB Log" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="default_tpa_bag_quantity" header="Default TPA Bag Quantity" body={TPABagTemplate} sortable style={{whiteSpace: 'nowrap', textAlign: 'right' }}></Column>
          <Column field="max_tpa_bag_quantity" header="Maximum TPA Bag Quantity" body={MaxTPABagTemplate} sortable style={{whiteSpace: 'nowrap', textAlign: 'right' }}></Column>
          <Column field="keep_plc_poll_hist_days" header={<span className="keep_plc_poll_hist_days-header-tooltip">Keep Poll History Days</span>} body={PollHistTemplate} sortable style={{whiteSpace: 'nowrap', textAlign: 'right' }}></Column>
          <Column field="no_of_purge_days" header={<span className="no_of_purge_days-header-tooltip">Max No of Days data Purge</span>} body={NoOfDaysTemplate} sortable style={{whiteSpace: 'nowrap', textAlign: 'right' }}></Column>
          <Column field="commit_batch_size" header={<span className="commit_batch_size-header-tooltip">Commit Num of Rows</span>} body={CommitTemplate} sortable style={{whiteSpace: 'nowrap', textAlign: 'right' }}></Column>
          <Column field="poll_data_arch_host_name" header={<span className="poll_data_arch_host_name-header-tooltip">Archive Sever IP Address</span>} sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="poll_data_arch_port_number" header="Archive Sever Port#" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="poll_data_arch_ftp_user" header="Archive Sever ftp User" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="poll_data_arch_ftp_password" header="Archive Sever ftp Password" body={(rowData) => PasswordTemplate(rowData, 'poll_data_arch_ftp_password')} sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="poll_data_dxp_host_name" header={<span className="poll_data_dxp_host_name-header-tooltip">Poll Data ftp Server</span>} sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="poll_data_dxp_port_number" header="Poll Data ftp Server Port#" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="poll_data_dxp_ftp_user" header="Poll Data ftp User" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="poll_data_dxp_ftp_password" header="Poll Data ftp Password" body={(rowData) => PasswordTemplate(rowData,'poll_data_dxp_ftp_password')} sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="poll_data_dxp_remote_base_dir" header={<span className="poll_data_dxp_remote_base_dir-header-tooltip">Poll Data server Base Directory</span>} sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="poll_data_dxp_local_base_dir" header={<span className="poll_data_dxp_local_base_dir-header-tooltip">Poll Data Oracle server Base Directory</span>} sortable style={{whiteSpace: 'nowrap' }}></Column>
        </DataTable>
      </div>
      )}

      <Dialog visible={showproductDialog} style={{ width: '1600px' }} header="System Options" modal className="p-fluid" onHide={hideDialog}>
                <div className="grid-container">
                  <div className="grid-item">
                    <h3>Organization and Inventory Details</h3>
                    <hr/>
                    <div className="info-row">
                    <label htmlFor="organization_code">Organization Code</label>
                    <div className="box1">
                        {row.organization_code}
                    </div>
                    </div>
                    <div className="info-row">
                    <label htmlFor="allow_negative_inventory_mean">Allow Negative Inventory?</label>
                    <div className="box1">
                        {row.allow_negative_inventory_mean}
                    </div>
                    </div>
                    <div className="info-row">
                    <label htmlFor="intransit_subinventory_code">Intrasit Subinventory</label>
                    <div className="box1">
                        {row.intransit_subinventory_code}
                    </div>
                    </div>
                  </div>
                  <div className="grid-item">
                    <h3>Aging Details</h3>
                    <hr/>
                    <div className="info-row">
                    <label htmlFor="default_ht_aging_hours">Default HT Aging Hours</label>
                    <div className="box1" style={{textAlign:"right"}}>
                        {HTNumber}
                    </div>
                    </div>
                    <div className="info-row">
                    <label htmlFor="default_rt_aging_hours">Default RT Aging Hours</label>
                    <div className="box1" style={{textAlign:"right"}}>
                        {RTNumber}
                    </div>
                    </div>
                  </div>
                  <div className="grid-item">
                    <h3>Shift Details</h3>
                    <hr/>
                    <div className="info-row">
                    <label htmlFor="auto_start_mg_shift_hrs_bef">Auto Start MG Shift Hours Before</label>
                    <div className="box1" style={{textAlign:"right"}}>
                        {MGBeforeNumber}
                    </div>
                    </div>
                    <div className="info-row">
                    <label htmlFor="auto_end_mg_shift_hrs_aft">Auto End MG Shift Hours After</label>
                    <div className="box1" style={{textAlign:"right"}}>
                        {MGAfterNumber}
                    </div>
                    </div>
                    <div className="info-row">
                    <label htmlFor="tpa_default_day_running_time">TPA Default Day Running Time</label>
                    <div className="box1">
                        {row.tpa_default_day_running_time}
                    </div>
                    </div>
                    <div className="info-row">
                    <label htmlFor="pdcs_start_eff_date">PDCS Start Eff Date</label>
                    <div className="box1">
                        {row.pdcs_start_eff_date}
                    </div>
                    </div>
                  </div>
                  <div className="grid-item">
                    <h3>Concurent Request Details</h3>
                    <hr/>
                    <div className="info-row">
                    <label htmlFor="conc_pgm_pdcs_user_name">Conc Pgm PDCS User</label>
                    <div className="box1">
                        {row.conc_pgm_pdcs_user_name}
                    </div>
                    </div>
                    <div className="info-row">
                    <label htmlFor="conc_pgm_rpt_user_name">Conc Rpt Submit User</label>
                    <div className="box1">
                        {row.conc_pgm_rpt_user_name}
                    </div>
                    </div>
                    <div className="info-row">
                    <label htmlFor="conc_pgm_rpt_resp_name">Conc Rpt Submit Resp</label>
                    <div className="box1">
                        {row.conc_pgm_rpt_resp_name}
                    </div>
                    </div>
                    <div className="info-row">
                    <label htmlFor="conc_pgm_rpt_resp_appl_name">Conc Rpt Submit Resp App</label>
                    <div className="box1">
                        {row.conc_pgm_rpt_resp_appl_name}
                    </div>
                    </div>
                  </div>
                  <div className="grid-item">
                    <h3>Log Details</h3>
                    <hr/>
                    <div className="info-row">
                    <label htmlFor="log_prefix_name">Log Prefix Name</label>
                    <div className="box1">
                        {row.log_prefix_name}
                    </div>
                    </div>
                    <div className="info-row">
                    <label htmlFor="enable_db_log_meaning">Enable DB Log</label>
                    <div className="box1">
                        {row.enable_db_log_meaning}
                    </div>
                    </div>
                  </div>
                  <div className="grid-item">
                    <h3>Bag Quantity  Details</h3>
                    <hr/>
                    <div className="info-row">
                    <label htmlFor="default_tpa_bag_quantity">Default TPA Bag Quantity</label>
                    <div className="box1" style={{textAlign:"right"}}>
                        {TPABagNumber}
                    </div>
                    </div>
                    <div className="info-row">
                    <label htmlFor="max_tpa_bag_quantity">Maximum TPA Bag Quantity</label>
                    <div className="box1" style={{textAlign:"right"}}>
                        {MaxTPABagNumber}
                    </div>
                    </div>
                  </div>
                  <div className="grid-item">
                    <h3>Poll Data Archive Details</h3>
                    <hr/>
                    <div className="info-row">
                    <label htmlFor="keep_plc_poll_hist_days">Keep Poll History Days</label>
                    <div className="box1" style={{textAlign:"right"}}>
                        {PollHistNumber}
                    </div>
                    </div>
                    <div className="info-row">
                    <label htmlFor="no_of_purge_days">Max No of Days data Purge</label>
                    <div className="box1" style={{textAlign:"right"}}>
                        {NoOfDaysNumber}
                    </div>
                    </div>
                    <div className="info-row">
                    <label htmlFor="commit_batch_size">Commit Num of Rows</label>
                    <div className="box1" style={{textAlign:"right"}}>
                        {CommitNumber}
                    </div>
                    </div>
                  </div>
                  <div className="grid-item">
                    <h3>Poll Data Server Info</h3>
                    <hr/>
                    <div className="info-row">
                    <label htmlFor="poll_data_dxp_host_name">Poll Data ftp Server</label>
                    <div className="box1">
                        {row.poll_data_dxp_host_name}
                    </div>
                    </div>
                    <div className="info-row">
                    <label htmlFor="poll_data_dxp_port_number">Poll Data ftp Server Port#</label>
                    <div className="box1">
                        {row.poll_data_dxp_port_number}
                    </div>
                    </div>
                    <div className="info-row">
                    <label htmlFor="poll_data_dxp_ftp_user">Poll Data ftp User</label>
                    <div className="box1">
                        {row.poll_data_dxp_ftp_user}
                    </div>
                    </div>
                    <div className="info-row">
                    <label htmlFor="poll_data_dxp_ftp_password">Poll Data ftp Password</label>
                    <div className="box1" onMouseDown={() => setIsPasswordVisible(true)} onMouseUp={() => setIsPasswordVisible(false)} onMouseLeave={() => setIsPasswordVisible(false)}>
                        {isPasswordVisble ? row.poll_data_dxp_ftp_password : getMaskedValue(row.poll_data_dxp_ftp_password)}
                    </div>
                    </div>
                    <div className="info-row">
                    <label htmlFor="poll_data_dxp_remote_base_dir">Poll Data server Base Directory</label>
                    <div className="box1">
                        {row.poll_data_dxp_remote_base_dir}
                    </div>
                    </div>
                    <div className="info-row">
                    <label htmlFor="poll_data_dxp_local_base_dir">Poll Data Oracle server Base Directory</label>
                    <div className="box1">
                        {row.poll_data_dxp_local_base_dir}
                    </div>
                    </div>
                  </div>
                  <div className="grid-item">
                    <h3>Legacy Poll Data Archive Server Info</h3>
                    <hr/>
                    <div className="info-row">
                    <label htmlFor="poll_data_arch_host_name">Archive Sever IP Address</label>
                    <div className="box1">
                        {row.poll_data_arch_host_name}
                    </div>
                    </div>
                    <div className="info-row">
                    <label htmlFor="poll_data_arch_port_number">Archive Sever Port#</label>
                    <div className="box1">
                        {row.poll_data_arch_port_number}
                    </div>
                    </div>
                    <div className="info-row">
                    <label htmlFor="poll_data_arch_ftp_user">Archive Sever ftp User</label>
                    <div className="box1">
                        {row.poll_data_arch_ftp_user}
                    </div>
                    </div>
                    <div className="info-row">
                    <label htmlFor="poll_data_arch_ftp_password">Archive Sever ftp Password</label>
                    <div className="box1" onMouseDown={() => setIsPasswordVisible1(true)} onMouseUp={() => setIsPasswordVisible1(false)} onMouseLeave={() => setIsPasswordVisible1(false)}>
                        {isPasswordVisble1 ? row.poll_data_arch_ftp_password : getMaskedValue(row.poll_data_arch_ftp_password)}
                    </div>
                    </div>
                  </div>
                </div>   

            </Dialog>
            <Dialog 
                header="API Error" 
                visible={errorDialogVisible} 
                style={{ width: '400px' }} 
                onHide={hideDialog} 
                footer={
                    <Button label="OK" onClick={hideDialog} className="p-button-primary p-button-rounded" />
                }
            >    
             <p>

                    {errorMessage}
                </p>
            </Dialog>
            </div>
    </div>
  );
};

export default SystemOptions;

