import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "assets/scss/dashboard.scss";
import "assets/css/charts_form_controls.css";
import "assets/css/grid_table.css";
import React, { useState, useRef, useLayoutEffect,useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { getDataApi, saveApi, loadApi } from "services/appApi";
import { pecaPageRows, pecaRowsPerPageOptions } from "pages/common/envPECA";

const PollDataErrors = () => {
  const emptyRow = {
    poll_data_intfc_id: null,
    machine_name: "",
    dxp_tag_name: "",
    machine_address_code: "",
    created_date_time: "",
    poll_data: "",
    ora_error_message: "",
    file_name: "",
    column_number: 0,
    machine_address_name: "",
    is_machine_stop: "N",
    is_production_count: "N",
    is_duration_second: "N",
    is_duration_minute: "N",
    file_id: 0,
    dxp_file_prefix: "",
    process_name: "",
    production_line_number: "",
    creation_date: ""
  };

  const [rows, setRows] = useState([]);
  const [rowDialog, setRowDialog] = useState(false);
  const [showproductDialog, setShowProductDialog] = useState(false);
  const [row, setRow] = useState(emptyRow);
  const [selectedRows, setSelectedRows] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingErrors, setLoadingErrors] = useState([]);
  const [wasDataFetchingSuccessful, setWasDataFetchingSuccessful] =useState(true);
  const [fileName, setFileName]=useState("");
  const [machineName, setMachineName]=useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const toast = useRef(null);
  const dt = useRef(null);
  
  const p_param = {
    p_file_id:null,
    p_file_name:null,
    p_process_id:null,
    p_line_number:null,
    p_machine_id:null,
    p_start_datetime:null,
    p_end_datetime:null,
    p_machine_name_like:null,
  };
  useEffect(() => {
    const fetchData = async () => {
        const yesterday = moment().subtract(1, "days").set({
          hour: 7,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        let formattedStartDate = yesterday.format("yyyy-MM-DD HH:mm");
        setStartDate(formattedStartDate);

        const tomorrow = moment().add(0, "days").set({
          hour: 6,
          minute: 59,
          second: 0,
          millisecond: 0,
        });
        let formattedEndDate = tomorrow.format("yyyy-MM-DD HH:mm");
        setEndDate(formattedEndDate);
      }

    fetchData();
  }, []);

  const handleLoadData = async () => {
    const p_param = {
      p_start_datetime: startDate,
      p_end_datetime: endDate,
      p_file_name: fileName,
      p_machine_name_like: machineName,
   };
   console.log(p_param);
    try {
      setIsLoading(true);
      const response = await loadApi(p_param,"/api/txn/getPollDataErrDetails");
      console.log("Fetched Data:", response.data);
      console.log(response.data[0]);
      console.log(response.data[1]);
      if (response.data.length > 0) {
        let successFlag = response.data[0];
        if (successFlag === "Y") {
          console.log("success=Y");
          setRows(response.data[1]);
          setIsLoading(false);
          setWasDataFetchingSuccessful(false);
        } else {
          //setRows(null);
          setRows([]);
          setIsLoading(false);
          setWasDataFetchingSuccessful(true);
          const errorInfo = response.data[2];
          // Set the error message and display the dialog
          setErrorMessage(errorInfo);
          setErrorDialogVisible(true);
        }
        //setSearch(response.data);
      } else {
        //setRows(null);
        setRows([]);
        setIsLoading(false);
        setWasDataFetchingSuccessful(true);
      }

      //setRows(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const openNew = () => {
    setRow(emptyRow);
    setSubmitted(false);
    setRowDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setRowDialog(false);
    setErrorDialogVisible(false);
  };

  const findIndexById = (id) => {
    return rows.findIndex((row) => row.poll_data_intfc_id === id);
  };
  const exportCSV = () => {
    dt.current.exportCSV();
};


  const header = (
    <div className="flex justify-content-between">
      {/* <span className="p-input-icon-right">
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span> */}
      <div className="mt-3 md:mt-0 flex md:flex-1 justify-content-end">
        <Button
          icon="pi pi-upload"
          className="p-button-primary p-button-rounded"
          onClick={exportCSV}
          tooltip="Export"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>
    </div>
  );

 

  const showProduct = (row) => {
    setRow({ ...row });
    setRowDialog(true);
}

  const editRow = (rowData) => {
    setRow({ ...rowData });
    setRowDialog(true);
  };


  const readBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-primary"
          onClick={() => showProduct(rowData)}
          tooltip="View"
          tooltipOptions={{ position: "bottom" }}
        />
      </React.Fragment>
    );
  };

  const getLoadingHeaderContents = () => {
    return (
      <React.Fragment>
        <div className="row d-flex justify-content-center m-5">
          <div className="col-auto">
            <div className="card shadow-sm border-primary">
              <div className="card-header bg-primary text-white">
                <h2 className="mb-0">
                DXP Poll Data Errors
                </h2>
              </div>
              <div className="card-body text-center">
                <h3>
                  <i className="fa-duotone fa-fw fa-spin-pulse fa-loader me-3"></i>{" "}
                  The page is loading...
                </h3>
              </div>
            </div>
          </div>
        </div>
  
      </React.Fragment>
    );
  }
  const getErrrorMsgContents = () => {
    return (
      <React.Fragment>
        <div class="row mt-5 justify-content-md-center">
          <div class="col-3">
            <div class="card border-secondary">
              <div class="card-header bg-dark text-white">
              DXP Poll Data Errors table is Unavailable
              </div>
              <div class="card-body card-body-error rounded-bottom">
                <ul>
                  {loadingErrors.map((error) => (
                    <li>
                      error
                    </li>
                  ))}
  
                </ul>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  const getSearchControls = () => (
    <React.Fragment>
      <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
      <label className="form-label">Search</label>
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search..."
            className="input-outline" />
             </div>
    </React.Fragment>
  );

  const getFileNameControl = () => (
    <React.Fragment>
      <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
      <label className="form-label">File Name</label>
            <InputText 
            type="text"
            id = "FileName"
            name="FileName" 
            onChange={(e) => setFileName(e.target.value)}
            placeholder="Enter file name"
            className="input-outline" />
             </div>
    </React.Fragment>
  );
  const getMachineNameControl = () => (
    <React.Fragment>
      <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
      <label className="form-label">Machine Name</label>
            <InputText 
            type="text"
            id = "MacName"
            name="MacName" 
            onChange={(e) => setMachineName(e.target.value)}
            placeholder="Enter machine name"
            className="input-outline" />
             </div>
    </React.Fragment>
  );
  
  
  const getStartEndDateControls = () => (
    <React.Fragment>
      <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
        <label className="form-label">Start Date</label>
        <input
          type="datetime-local"
          id="StartDateTime"
          name="StartDateTime"
          defaultValue={startDate}
          onChange={handleStartDateChange}
          className="input-outline"
        />
      </div>
      <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
        <label className="form-label">End Date</label>
        <input
          type="datetime-local"
          id="EndDateTime"
          name="EndDateTime"
          defaultValue={endDate}
          onChange={handleEndDateChange}
          className="input-outline"
        />
      </div>
    </React.Fragment>
  );
  
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };
  
  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  
  const getGoButtonControl = () => (
    <React.Fragment>
        <div className="col-auto form-group my-4 ps-1 pt-1 pe-8 d-flex flex-column">
          <button
            type="button"
            className="btn btn-primary load-button"
            id={"chartAreaId"}
            onClick={handleLoadData}
            disabled={isLoading}
          >
            {isLoading ? (
                  <i className="pi pi-spin pi-spinner"></i>
                ) : null}
            <span>{isLoading ? "Loading" : "Go"}</span>
          </button>
        </div>
    </React.Fragment>
    );
  
  const getLoadedHeaderContents = () => {
    return (
      <React.Fragment>
        <div className="header-container">
          <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
          <i>
             
            
            <span className="normal-font"> DXP Poll Data Errors </span><br/><br/>

         
            </i>
          </h2>
        </div>
      </React.Fragment>
    );
  }

  const formatCreationDate = (rowData) => {
    return moment(rowData.creation_date).format('YYYY-MM-DD HH:mm:ss');
  };
  const formatDate = moment(row.creation_date).format('YYYY-MM-DD HH:mm:ss');
  return (
    <div>
      <div className="col-12">
      {getLoadedHeaderContents()}
      {loadingErrors.length > 0 && getErrrorMsgContents()}
      <div className="row">
      <div className="col">
        <div className="row justify-content-center">
          {getSearchControls()} 
          {getStartEndDateControls()} 
          {getFileNameControl()}
          {getMachineNameControl()}
          {getGoButtonControl()}
        </div>
      {!wasDataFetchingSuccessful && (
        <div className="card">
          {/* <div className="text-3xl text-800 font-bold mb-4">Dxp Poll Data Errors</div> */}
          <Toast ref={toast} />
          <DataTable
                    ref={dt}
                    value={rows}
                    scrollable scrollHeight="flex"
                    dataKey="poll_data_intfc_id"
                    className="custom-datatable-border"
                    tableStyle={{ minWidth: '50rem' }}
                    paginator
                    rows={pecaPageRows}
                    rowsPerPageOptions={pecaRowsPerPageOptions}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} data"
                    globalFilter={globalFilter}
                    header={header}
                    emptyMessage="No data found"
                    stripedRows
                    showGridlines
                >
            <Column body={readBodyTemplate} exportable={false} style={{width:'0.75rem' }}></Column>
            <Column field="creation_date" header="Date" sortable body={formatCreationDate} style={{whiteSpace: 'nowrap'}} />
            <Column field="machine_name" header="Machine Name" sortable style={{whiteSpace: 'nowrap'}} />
            <Column field="dxp_tag_name" header="TagName" sortable style={{whiteSpace: 'nowrap'}} />
            <Column field="machine_address_code" header="MachAddress" sortable style={{whiteSpace: 'nowrap'}} />
            <Column field="created_date_time" header="Date/Time" sortable style={{whiteSpace: 'nowrap'}} />
            <Column field="poll_data" header="PollData" sortable style={{whiteSpace: 'nowrap'}} />
            <Column field="ora_error_message" header="Ora Error Message" sortable style={{whiteSpace: 'nowrap'}} />
            <Column field="file_name" header="File Name" sortable style={{whiteSpace: 'nowrap'}} />
            <Column field="column_number" header="Col#" sortable style={{whiteSpace: 'nowrap'}} />
            <Column field="machine_address_name" header="AddressName" sortable style={{whiteSpace: 'nowrap'}} />
            <Column field="is_machine_stop" header="MachineStop?" sortable style={{whiteSpace: 'nowrap'}}/>
            <Column field="is_production_count" header="ProdCount" sortable style={{whiteSpace: 'nowrap'}} />
            <Column field="is_duration_second" header="DurInSecs?" sortable style={{whiteSpace: 'nowrap'}} />
            <Column field="is_duration_minute" header="DurInMins?" sortable style={{whiteSpace: 'nowrap'}} />
            <Column field="file_id" header="File Id" sortable hidden exportable={false} />
            <Column field="dxp_file_prefix" header="Dxp File Prefix" sortable hidden exportable={false} />
            <Column field="process_name" header="Process Name" sortable hidden exportable={false}/>
            <Column field="production_line_number" header="Production Line Number" sortable hidden exportable={false}/>
            <Column field="poll_data_intfc_id" header="Poll Data Intfc ID" sortable style={{whiteSpace: 'nowrap'}} />
          </DataTable>

          <Dialog
            visible={rowDialog}
            style={{ width: "450px" }}
            header="Poll Data Error Details"
            modal
            onHide={hideDialog}
          >
              <label htmlFor="creation_date">Date</label>
                    <div className="box">
                        {formatDate}
                    </div>

                <label htmlFor="machine_name">Machine Name</label>
                    <div className="box">
                        {row.machine_name}
                    </div>

                <label htmlFor="dxp_tag_name">TagName</label>
                    <div className="box">
                        {row.dxp_tag_name}
                    </div>

                <label htmlFor="machine_address_code">MachAddress</label>
                    <div className="box">
                        {row.machine_address_code}
                    </div>
                    <label htmlFor="created_date_time">Date/Time</label>
                    <div className="box">
                        {row.created_date_time}
                    </div>
                <label htmlFor="poll_data">PollData</label>
                    <div className="box">
                        {row.poll_data}
                    </div>

                <label htmlFor="ora_error_message">Ora Error Message</label>
                    <div className="box">
                        {row.ora_error_message}
                    </div>
                    <label htmlFor="file_name">File Name</label>
                    <div className="box">
                        {row.file_name}
                    </div>

                <label htmlFor="column_number">Col#</label>
                    <div className="box">
                        {row.column_number}
                    </div>

                <label htmlFor="machine_address_name">AddressName</label>
                    <div className="box">
                        {row.machine_address_name}
                    </div>

                <label htmlFor="is_machine_stop">MachineStop?</label>
                    <div className="box">
                        {row.is_machine_stop}
                    </div>

                <label htmlFor="is_production_count">ProdCount</label>
                    <div className="box">
                        {row.is_production_count}
                    </div>

                <label htmlFor="is_duration_second">DurInSecs?</label>
                    <div className="box">
                        {row.machine_address_name}
                    </div>

                <label htmlFor="is_duration_minute">DurInMins?</label>
                    <div className="box">
                        {row.is_machine_stop}
                    </div>

                <label htmlFor="poll_data_intfc_id">Poll Data Intfc ID</label>
                    <div className="box">
                        {row.poll_data_intfc_id}
                    </div>
          </Dialog>

          <Dialog 
                header="API Error" 
                visible={errorDialogVisible} 
                style={{ width: '400px' }} 
                onHide={hideDialog} 
                footer={
                    <Button label="OK" onClick={hideDialog} className="p-button-primary p-button-rounded" />
                }
            >    
             <p>

                    {errorMessage}
                </p>
            </Dialog>
        </div>
      )}
      </div>
    </div>
    </div>
    </div>
  );
};

export default PollDataErrors;
