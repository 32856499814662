import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "assets/scss/dashboard.scss";
import "assets/css/charts_form_controls.css";
import React, { useState, useRef, useLayoutEffect,useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import "assets/css/grid_table.css";
import moment from "moment";
import { getDataApi, saveApi, loadApi } from "services/appApi";
import { pecaPageRows, pecaRowsPerPageOptions } from "pages/common/envPECA";

const LookupValues  = () => {
  let emptyRow = {
   lookup_type : "",
   lookup_code : "",
   meaning : "",
   description : "",
   enabled_flag : "",
   sort_key : ""
  };

  const [rows, setRows] = useState([]);
  const [rowDialog, setRowDialog] = useState(false);
  const [showproductDialog, setShowProductDialog] = useState(false);
  const [row, setRow] = useState(emptyRow);
  const [selectedRows, setSelectedRows] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingErrors, setLoadingErrors] = useState([]);
  const [wasDataFetchingSuccessful, setWasDataFetchingSuccessful] =useState(true);
  const [lookupType, setLookupType]=useState("");
  const [lookupCode, setLookupCode]=useState("");
  const [meaning, setMeaning] = useState();
  const [enabledFlag, setEnabledFlag] = useState("Y");
  const toast = useRef(null);
  const dt = useRef(null);
 
    const handleLoadData = async () => {
      const p_param = {
        p_lookup_type: lookupType,
        p_lookup_code: lookupCode,
        p_meaning: meaning,
        p_enabled_flag: enabledFlag,
     };
     console.log(p_param);
      try {
        setIsLoading(true);
        const response = await loadApi(p_param,"/api/setup/lookupValues/selRows");
        console.log("Fetched Data:", response.data);
        console.log(response.data[0]);
        console.log(response.data[1]);
        if (response.data.length > 0) {
          let successFlag = response.data[0];
          if (successFlag === "Y") {
            console.log("success=Y");
            setRows(response.data[1]);
            setIsLoading(false);
            setWasDataFetchingSuccessful(false);
          } else {
            //setRows(null);
            setRows([]);
            setIsLoading(false);
            setWasDataFetchingSuccessful(true);
            const errorInfo = response.data[2];
            // Set the error message and display the dialog
            setErrorMessage(errorInfo);
            setErrorDialogVisible(true);
          }
          //setSearch(response.data);
        } else {
          //setRows(null);
          setRows([]);
          setIsLoading(false);
          setWasDataFetchingSuccessful(true);
        }

        //setRows(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

  const hideDialog = () => {
    setSubmitted(false);
    setRowDialog(false);
    setShowProductDialog(false);
    setErrorDialogVisible(false);
  };

  const showProduct = (row) => {
    setRow({ ...row });
    setShowProductDialog(true);
}

const exportCSV = () => {
  dt.current.exportCSV();
};

const getLoadingHeaderContents = () => {
  return (
    <React.Fragment>
      <div className="row d-flex justify-content-center m-5">
        <div className="col-auto">
          <div className="card shadow-sm border-primary">
            <div className="card-header bg-primary text-white">
              <h2 className="mb-0">
              Inquire Lookup
              </h2>
            </div>
            <div className="card-body text-center">
              <h3>
                <i className="fa-duotone fa-fw fa-spin-pulse fa-loader me-3"></i>{" "}
                The page is loading...
              </h3>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}
const getLoadedHeaderContents = () => {
  return (
    <React.Fragment>
      <div className="header-container">
        <h2 className="text-center my-3 d-flex flex-column flex-md-row align-items-center justify-content-center">
        <i>
           
          
          <span className="normal-font"> Inquire Lookup </span><br/><br/>
          {/* <div className="header-container p-1">
              <style>
              {`
                input::placeholder {
                  font-size: 17px;
                }
              `}
            </style>
          <span className="p-input-icon-right w-full md:w-auto"> 
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto text-sm p-3"
          style={{ fontSize: '17px' }} 
        />
      </span>
          </div> */}
       
          </i>
        </h2>
      </div>
    </React.Fragment>
  );
}
const getErrrorMsgContents = () => {
  return (
    <React.Fragment>
      <div class="row mt-5 justify-content-md-center">
        <div class="col-3">
          <div class="card border-secondary">
            <div class="card-header bg-dark text-white">
            Inquire Lookup table is Unavailable
            </div>
            <div class="card-body card-body-error rounded-bottom">
              <ul>
                {loadingErrors.map((error) => (
                  <li>
                    error
                  </li>
                ))}

              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
const getSearchControls = () => (
  <React.Fragment>
    <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
    <label className="form-label">Search</label>
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="input-outline" />
           </div>
  </React.Fragment>
);

const getLookupTypeControl = () => (
  <React.Fragment>
    <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
    <label className="form-label">Lookup Type</label>
          <InputText 
          type="text"
          id = "LookupType"
          name="LookupType" 
          onChange={(e) => setLookupType(e.target.value)}
          placeholder="Enter Lookup Type"
          className="input-outline" />
           </div>
  </React.Fragment>
);
const getLookupCodeControl = () => (
  <React.Fragment>
    <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
    <label className="form-label">Lookup Code</label>
          <InputText 
          type="text"
          id = "LookupCode"
          name="LookupCode" 
          onChange={(e) => setLookupCode(e.target.value)}
          placeholder="Enter Lookup Code"
          className="input-outline" />
           </div>
  </React.Fragment>
);
const getmeaningControl = () => (
    <React.Fragment>
      <div className="col-auto form-group ps-1 pe-0 d-flex flex-column">
      <label className="form-label">Meaning</label>
            <InputText 
            type="text"
            id = "Meaning"
            name="Meaning" 
            onChange={(e) => setMeaning(e.target.value)}
            placeholder="Enter Meaning"
            className="input-outline" />
             </div>
    </React.Fragment>
  );
  const handleRadioChange = (e) => {
    setEnabledFlag(e.target.value); 
  };
  const getEnabledFlagControl = () => (
    <React.Fragment>
      <div className="col-auto px-1 d-flex flex-column">
        <label className="form-label">Enabled?</label>
        <div className="d-flex align-items-center">
          <div className="form-check me-3">
            <input
              className="form-check-input"
              type="radio"
              id="flag_Y"
              name="enabledFlag"
              value="Y"
              checked={enabledFlag === "Y"}
              onChange={handleRadioChange}
            />
            <label className="form-check-label" htmlFor="flag_Y">
              Yes
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              id="flag_N"
              name="enabledFlag"
              value="N"
              checked={enabledFlag === "N"}
              onChange={handleRadioChange}
            />
            <label className="form-check-label" htmlFor="flag_N">
              No
            </label>
          </div>
        </div>
      </div>
    </React.Fragment>
  );


const getGoButtonControl = () => (
  <React.Fragment>
      <div className="col-auto form-group my-4 ps-1 pt-1 pe-8 d-flex flex-column">
        <button
          type="button"
          className="btn btn-primary load-button"
          id={"chartAreaId"}
          onClick={handleLoadData}
          disabled={isLoading}
        >
          {isLoading ? (
                  <i className="pi pi-spin pi-spinner"></i>
                ) : null}
          <span>{isLoading ? "Loading" : "Go"}</span>
        </button>
      </div>
  </React.Fragment>
  );

  const header = (
    <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
      {/* <span className="p-input-icon-right w-full md:w-auto">
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="w-full lg:w-auto"
        />
      </span> */}
     <div className="mt-3 md:mt-0 flex md:flex-1 justify-content-end">
        <Button
          icon="pi pi-upload"
          className="p-button-primary p-button-rounded"
          onClick={exportCSV}
          tooltip="Export"
          tooltipOptions={{ position: "bottom" }}
        />
      </div>
    </div>
  );


  const readBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-primary"
          onClick={() => showProduct(rowData)}
          tooltip="View"
          tooltipOptions={{ position: "bottom" }}
        />
      </React.Fragment>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="col-12">
      {getLoadedHeaderContents()}
      {loadingErrors.length > 0 && getErrrorMsgContents()}
      <div className="row">
      <div className="col">
        <div className="row justify-content-center">
          {getSearchControls()} 
          {getLookupTypeControl()} 
          {getLookupCodeControl()}
          {getmeaningControl()}
          {getEnabledFlagControl()}
          {getGoButtonControl()}
        </div>
      {!wasDataFetchingSuccessful && (
      <div className="card">
        {/* <div className="text-3xl text-800 font-bold mb-4">DXP Poll Data Files</div> */}
        <DataTable
          ref={dt}
          value={rows}
          scrollable scrollHeight="flex"     
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          dataKey="sort_key"
          className="custom-datatable-border"
          tableStyle={{ minWidth: '50rem' }}
          paginator
          rows={pecaPageRows}
          rowsPerPageOptions={pecaRowsPerPageOptions}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} data"
          globalFilter={globalFilter}
          header={header}
          emptyMessage="No data found"
          stripedRows
          showGridlines
        >
         
          <Column body={readBodyTemplate} exportable={false} style={{width:'0.75rem' }}></Column>
          <Column field="lookup_type" header="Lookup Type" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="lookup_code" header="Lookup Code" sortable style={{whiteSpace: 'nowrap' }}></Column>
          <Column field="meaning" header="Meaning" sortable></Column>
          <Column field="description" header="Description" sortable></Column>
          <Column field="enabled_flag" header="Enabled?" sortable></Column>
          <Column field="sort_key" header="Sort Key" sortable style={{whiteSpace: 'nowrap' }}></Column>
        </DataTable>
      </div>
      )}
      </div>
      </div>

      <Dialog visible={showproductDialog} style={{ width: '600px' }} header="Inquire Lookup" modal className="p-fluid" onHide={hideDialog}>
                
                <label htmlFor="lookup_type">Lookup Type</label>
                    <div className="box">
                        {row.lookup_type}
                    </div>

                <label htmlFor="lookup_code">Lookup Code</label>
                    <div className="box">
                        {row.lookup_code}
                    </div>

                <label htmlFor="meaning">Meaning</label>
                    <div className="box">
                        {row.meaning}
                    </div>

                <label htmlFor="description">Description</label>
                    <div className="box">
                        {row.description}
                    </div>

                <label htmlFor="enabled_flag">Enabled?</label>
                    <div className="box">
                        {row.enabled_flag}
                    </div>

                <label htmlFor="sort_key">Sort Key</label>
                    <div className="box">
                        {row.sort_key}
                    </div>

            </Dialog>
            <Dialog 
                header="API Error" 
                visible={errorDialogVisible} 
                style={{ width: '400px' }} 
                onHide={hideDialog} 
                footer={
                    <Button label="OK" onClick={hideDialog} className="p-button-primary p-button-rounded" />
                }
            >    
             <p>

                    {errorMessage}
                </p>
            </Dialog>
            </div>
    </div>
  );
};

export default LookupValues;

